/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTodo = /* GraphQL */ `
  mutation CreateTodo(
    $input: CreateTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    createTodo(input: $input, condition: $condition) {
      id
      name
      description
      type
      thc
      cbd
      effect
      flavor
      history
      settings
      location
      likes
      dislikes
      cartLikes
      cartDislikes
      concLikes
      concDislikes
      eddLikes
      eddDislikes
      color
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTodo = /* GraphQL */ `
  mutation UpdateTodo(
    $input: UpdateTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    updateTodo(input: $input, condition: $condition) {
      id
      name
      description
      type
      thc
      cbd
      effect
      flavor
      history
      settings
      location
      likes
      dislikes
      cartLikes
      cartDislikes
      concLikes
      concDislikes
      eddLikes
      eddDislikes
      color
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTodo = /* GraphQL */ `
  mutation DeleteTodo(
    $input: DeleteTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    deleteTodo(input: $input, condition: $condition) {
      id
      name
      description
      type
      thc
      cbd
      effect
      flavor
      history
      settings
      location
      likes
      dislikes
      cartLikes
      cartDislikes
      concLikes
      concDislikes
      eddLikes
      eddDislikes
      color
      createdAt
      updatedAt
      __typename
    }
  }
`;
