import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { withAuthenticator  } from '@aws-amplify/ui-react';
import Chatbot from 'react-chatbot-kit';
import ActionProvider from './WAIDActionProvider';
import MessageParser from './WAIDMessageParser';
import { createChatBotMessage } from "react-chatbot-kit";
import { createTodo} from '../graphql/mutations';
import { fetchAuthSession } from 'aws-amplify/auth';
import {
  Flex,
//  Heading,
//  Image,
Button,
//  View,
} from "@aws-amplify/ui-react";

import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import ButtonMui from "@mui/material/Button";

import { generateClient } from 'aws-amplify/api';
import { todosByDescription } from '../graphql/queries';
import appConfig from '../AppConfig';
import { Amplify } from 'aws-amplify';
import awsExports from '../aws-exports';
Amplify.configure(awsExports);




//function Home() {
function Home({signOut,user}) {
  const client = generateClient();
  const navigate = useNavigate();
  const ver = appConfig.name  + " "+ appConfig.ver ;
  const ver1 = appConfig.name ;
  const ver2 = appConfig.ver ;
  const [hist, setHist] = useState("");
const [email, setEmail] = useState("");
  const [comment, setComment] = useState("");

  useEffect(() => {
    
    getUserDoc();
    getEmail();
    }, []);
  

  const [open1, setOpen1] = useState(false);
  const handleToClose1 = () => {
      setOpen1(false);  
  };
  const handleClickToOpen1 = () => {
    setOpen1(true);
  };

  const [open2, setOpen2] = useState(false);
  const handleToClose2 = () => {
      setOpen2(false);  
  };
  const handleClickToOpen2 = () => {
    setOpen2(true);
  };

  const [open3, setOpen3] = useState(false);
  const handleToClose3 = () => {
      setOpen3(false);  
  };
  const handleClickToOpen3 = () => {
    setOpen3(true);
  };

  const [open4, setOpen4] = useState(false);
  const handleToClose4 = () => {
      setOpen4(false);  
  };
  const handleClickToOpen4 = () => {
    setOpen4(true);
  };

  
  const [open5, setOpen5] = useState(false);
  const handleToClose5 = () => {
      setOpen5(false);  
  };
  const handleClickToOpen5 = () => {
    setOpen5(true);
  };



const [open8, setOpen8] = useState(false);
const handleToClose8 = () => {
    setOpen8(false);  
};
const handleClickToOpen8 = () => {
  handleToClose1();
  setOpen8(true);
};

const handleSubmit8 = (e) => {
  e.preventDefault();
  //👇🏻 calls the function
    addFeedback();
    handleToClose8();
};

const handleClickToOpenBugReport = (e) => {
  setOpen1(false);
  reportBug()
};

const config = { 
  botName: "Bud-Tender Bot",
 initialMessages: [  createChatBotMessage("Welcome! How may I help you?")],
   customStyles: {
     botMessageBox: {
       //backgroundColor: "#376B7E",
       backgroundColor: "#4c663b",
       minWidth: "700px",
     },
     chatButton: {
      //  /backgroundColor: "#376B7E",
       backgroundColor: "#4c663b",
     },
   },
 }
 


function blank() {
  
};

function goSettings() {
  navigate(`/settings`);
};

function goProducts() {
  navigate(`/products`);
};

function goFavorites() {
  navigate(`/favorites`);
};




   async function getEmail() {
   try {
    const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
    //console.log(idToken.payload.email)
    setEmail(idToken.payload.email);
    //setHideChat(false)
  } catch (err) {
    console.log(err);
  }};

async function getUserDoc() {
  


  try {
    //Get user doc
    console.log('Get user doc ' + user.username);
    const oneTodo = await client.graphql({
    query: todosByDescription,
    variables: { name: user.username,  sortDirection: 'ASC' }
      });
    //  console.log("SettingsDoc User(Home):" + user.username);    
    //  console.log('Loaded User Doc')
    //  var tmpArray = oneTodo.data.todosByDescription.items;
     // console.log(tmpArray);
    } catch (e) {
      console.log('Get User Settings Doc Failed(Home): ', e);


    try {
      //get user doc2
      console.log('Get user doc2 ' + user.username);
          const oneTodo = await client.graphql({
          query: todosByDescription,
          variables: { name: user.username }
          });
     //     console.log("SettingsDoc User(Home2):" + user.username);    
      //    console.log('Loaded User Doc2');
       //   var tmpArray = oneTodo.data.todosByDescription.items;
       ////   console.log(tmpArray);
      } catch (e) {
          console.log('Get User Settings Doc Failed(Home2): ', e);

          try {
            //Create new user
            console.log('creating new user record')
              const result = await client.graphql({
            query: createTodo,
            variables: {
              input: {
               name: user.username ,
               description: 'user doc' ,
               settings: appConfig.settings,
               likes: appConfig.likes,
               dislikes: appConfig.dislikes,
               cartLikes: appConfig.likes,
               cartDislikes: appConfig.dislikes,
               concLikes: appConfig.likes,
               concDislikes: appConfig.dislikes,
               eddLikes: appConfig.likes,
               eddDislikes: appConfig.dislikes,
               history: appConfig.history
              }
            }
          });
          console.log('user created');
        } catch (e) {
          console.log('Create new user Failed(Home): ', e);
        };


     };




  };

  
}





  
  
  async function addFeedback() {
  
  if (comment==='') {
alert('Feedback was blank! Aborting.')

  } else {
  
       try {
        //Get user doc
        const oneTodo = await client.graphql({
        query: todosByDescription,
        variables: { name: user.username }
          });
      //    console.log("SettingsDoc4 User:" + user.username);    
      //console.log(oneTodo);
        var tmpHist= oneTodo.data.todosByDescription.items[0].history;
          setHist(tmpHist);
     //console.log('Loaded User Doc4')
  
  
  //create new bug record
  try {
    const result = await client.graphql({
       query: createTodo,
      variables: {
         input: {
          name: 'feedback',
          description: email,
          history: comment 
         }
       }
     });
     alert('Your feedback has been submitted. Thank you!')
     //reset input form
     setComment('')
     //console.log(result);
     } catch (e) {
       console.log('Create Feedback Record Failed: ', e);
       alert("Feedback Submit Error!")
     };
  

        } catch (e) {
        console.log('Get User Settings Doc4 Failed(FB): ', e);
        };
      }
  };
  

async function reportBug() {
  
   const input = prompt("Please enter a short bug description");
   if (input === null) { 
      //No Desc, Cancel
     // alert("Bug report aborted");

   } else {
     //Do bug report
  

     try {
      //Get user doc
      const oneTodo = await client.graphql({
      query: todosByDescription,
      variables: { name: user.username }
        });
    //    console.log("SettingsDoc3 User(Home):" + user.username );    
    //console.log(oneTodo);
      var tmpHist= oneTodo.data.todosByDescription.items[0].history;
        setHist(tmpHist);
   //console.log('Loaded User Doc3')


//create new bug record
try {
  const result = await client.graphql({
     query: createTodo,
    variables: {
       input: {
        name: 'bug',
        description: 'bug report',
        history: "From: " + email + ' Desc: ' + input + ' Data: ' + tmpHist
       }
     }
   });
   alert("Your bug description and conversation history have been submitted.  Thank you!");
   //console.log(result);
   } catch (e) {
     console.log('Create Bug Record Failed(Home): ', e);
   };



      } catch (e) {
      console.log('Get User Settings Doc3 Failed(Home): ', e);
      };





  

  };


    };
    


    

  return (
    <>



<div className="Headder"       
        >
          <span style={{ }}>
        {ver1}
        </span>

 

        <span style={{paddingLeft:'1vw'}} >

        <span style={{ paddingRight:"4px",fontSize:"11px"}} >
 
        </span>

        <button className="HeadderButton" onClick={goFavorites}>Favorites</button>
        <button className="HeadderButton" onClick={goProducts}>Strains</button>
        <button className="HeadderButton" onClick={handleClickToOpen1}>Feedback</button>
        <button className="HeadderButton"  onClick={goSettings}>Settings</button>
        
        
        </span>


        </div>


<div className = "main2">

<div className="titleHome"  >

  <span>
ALPHA {ver2}
</span>

<span style={{paddingLeft:'30px'}}>
Mode:
<select name="mode">
  <option value="volvo">Flower</option>
  <option value="saab">Cartridge</option>
  <option value="mercedes">Concentrate</option>
  <option value="audi">Eddible</option>
</select>
</span>

</div>

<div className = "body-products" >
    <Chatbot config={config} actionProvider={ActionProvider} messageParser={MessageParser}  />
   

    </div>
  
  </div>




   



  <div style={{}}> 
      <Dialog open={open1} onClose={handleToClose1} >
        <DialogTitle style={{backgroundColor:'#242424', color:'#d8d8d8', borderBottomStyle:'none', borderTopStyle:'solid',borderLeftStyle:'solid',borderRightStyle:'solid', borderWidth:'2px', borderColor:'#4c663b'}}>
          Feedback Type
        </DialogTitle>
        <DialogContent style={{backgroundColor:'#242424', color:'white', borderStyle:'none', borderBottomStyle:'solid', borderTopStyle:'none',borderLeftStyle:'solid',borderRightStyle:'solid', borderWidth:'2px', borderColor:'#4c663b'}}>
          <DialogContentText>
            
            <Flex
              direction='column'
            >

                                 <Button style={{minWidth:'100px', backgroundColor:'', color:"#a0a0a0"}} onClick={handleClickToOpenBugReport} >Bug Report</Button>
                                            <Button style={{minWidth:'100px', backgroundColor:'', color:"#a0a0a0"}} onClick={handleClickToOpen8} >General</Button>
                                            
                                            
                      
                      
                      <Button style={{minWidth:'100px', backgroundColor:'', color:"#a0a0a0"}} onClick={handleToClose1}>Close</Button>
                   
      
            </Flex>

              


          </DialogContentText> 
        </DialogContent>

      </Dialog>
    </div>


    <div stlye={{}}> 
      <Dialog open={open2} onClose={handleToClose2}>
        <DialogTitle>
          Title
        </DialogTitle>
        <DialogContent >
          <DialogContentText>
          ...under construction
          </DialogContentText> 
        </DialogContent>
        <DialogActions> 
          <ButtonMui onClick={handleToClose2}>
            Exit
          </ButtonMui> 
        </DialogActions>      
      </Dialog>
    </div>




    <div stlye={{}}> 
      <Dialog open={open3} onClose={handleToClose3}>
        <DialogTitle>
          Title
        </DialogTitle>
        <DialogContent >
          <DialogContentText>
          ...under construction
          </DialogContentText> 
        </DialogContent>
        <DialogActions> 
          <ButtonMui onClick={handleToClose3}>
            Exit
          </ButtonMui> 
        </DialogActions>      
      </Dialog>
    </div>



    <div stlye={{}}> 
      <Dialog open={open4} onClose={handleToClose4}>
        <DialogTitle>
          Title
        </DialogTitle>
        <DialogContent >
          <DialogContentText>
          ...under construction
          </DialogContentText> 
        </DialogContent>
        <DialogActions> 
          <ButtonMui onClick={handleToClose4}>
            Exit
          </ButtonMui> 
        </DialogActions>      
      </Dialog>
    </div>

    <div stlye={{}}> 
      <Dialog open={open5} onClose={handleToClose5}>
        <DialogTitle>
          Title
        </DialogTitle>
        <DialogContent >
          <DialogContentText>
          ...under construction
          </DialogContentText> 
        </DialogContent>
        <DialogActions> 
          <ButtonMui onClick={handleToClose5}>
            Exit
          </ButtonMui> 
        </DialogActions>      
      </Dialog>
    </div>




    <Dialog open={open8} onClose={handleToClose8}>
        <DialogTitle style={{backgroundColor:'#242424', color:'#d8d8d8', borderBottomStyle:'none', borderTopStyle:'solid',borderLeftStyle:'solid',borderRightStyle:'solid', borderWidth:'2px', borderColor:'#4c663b'}}>
          
             Send General Feedback

        </DialogTitle>
        <DialogContent style={{backgroundColor:'#242424', color:'#d8d8d8', borderBottomStyle:'solid', borderTopStyle:'none',borderLeftStyle:'solid',borderRightStyle:'solid', borderWidth:'2px', borderColor:'#4c663b'}}>
          <DialogContentText>
            
          <Flex 
          direction="column"
          textAlign="center"
          alignItems="center"
 
          minWidth="200px"
            
          minHeight="55vh"
          gap="0.5vw"
          paddingTop="1vw"
          fontSize="14px"
          >
            
			<form  onSubmit={handleSubmit8}>
    
        <textarea
            
            rows={17}
			cols={40}
           
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            type='text'
            name='comment'
            
        />

             <Flex 
          direction="column"
          gap = "10px"
          paddingTop='15px'
          >
              <Button  style={{backgroundColor:'', color:"#80b35e" , fontWeight:'normal'}} onClick={handleSubmit8}  >Send</Button>
              <Button  style={{backgroundColor:'', color:"#a0a0a0" , fontWeight:'normal'}} onClick={handleToClose8}  >Cancel</Button>
              </Flex>
		</form>

          
            
            </Flex>


          </DialogContentText> 
        </DialogContent>
        
      </Dialog>
    



    </>
);

};

export default withAuthenticator(Home);
