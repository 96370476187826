import React, { useState} from "react";
import { generateClient } from 'aws-amplify/api';
import { toast } from "react-toastify";
import { createTodo, updateTodo, deleteTodo } from '../graphql/mutations';
import { listTodos, getTodo, todosByDescription } from '../graphql/queries';
import { Amplify } from 'aws-amplify';
import awsExports from '../aws-exports';
import { getCurrentUser } from 'aws-amplify/auth';
Amplify.configure(awsExports);
const client = generateClient();

function LikeButtonStrain( props)  {

  //function snotify(note) {toast.success(note);};
  //function inotify(note) {toast.info(note)};
  function enotify(note) {toast.error(note)};
  //function wnotify(note) {toast.warning(note)};

    const client = generateClient();


    const [state, setState] = useState("--");
    const [list, setList] = useState("--");


//console.log(props.likeList);
//console.log(props.uname)

    function stateChange(f) {
        setState(f);
        props.funct(f);
    //   to set loader
  };


  function listChange(f) {
    setList(f);
    props.functList(f);
//   to set list change
};


    async function handleLike() {
        
    
   
      //call listChange witrh new list
      listChange(props.name);
     console.log('Like button pressed')
          
    };



    return (
            <>
            <button className='product' style={{borderWidth:'0px',backgroundColor:'darkgreen', color:'white'}} onClick={handleLike} >L</button> 
            </>
    );
};

export default LikeButtonStrain;