import React, { useState} from "react";
import { generateClient } from 'aws-amplify/api';
import { toast } from "react-toastify";
import { createTodo, updateTodo, deleteTodo } from '../graphql/mutations';
import { listTodos, getTodo, todosByDescription } from '../graphql/queries';
import { Amplify } from 'aws-amplify';
import awsExports from '../aws-exports';
import { getCurrentUser } from 'aws-amplify/auth';
Amplify.configure(awsExports);
const client = generateClient();


function DislikeButtonStrain( props)  {

  //function snotify(note) {toast.success(note);};
  //function inotify(note) {toast.info(note)};
  function enotify(note) {toast.error(note)};
  //function wnotify(note) {toast.warning(note)};

    const client = generateClient();


    const [state, setState] = useState("--");
    const [list, setList] = useState("--");




    function stateChange(f) {
        setState(f);
        props.funct(f);
    //   to set loader
  };


  function listChange(f) {
    setList(f);
    props.functList(f);
//   to set list change
};


    async function handleDislike() {
        

   
      //call listChange witrh new list
      listChange(props.name);
     console.log('DisLike button pressed')
         
    };






    return (
            <>
            <button className='product' style={{borderWidth:'0px',backgroundColor:'darkorange', color:'white'}} onClick={handleDislike} >D</button> 
            </>
    );
};

export default DislikeButtonStrain;