import React, { useState} from "react";
import { generateClient } from 'aws-amplify/api';
import { toast } from "react-toastify";
import { createTodo, updateTodo, deleteTodo } from '../graphql/mutations';
import { listTodos, getTodo, todosByDescription } from '../graphql/queries';
import { Amplify } from 'aws-amplify';
import awsExports from '../aws-exports';
import { getCurrentUser } from 'aws-amplify/auth';
Amplify.configure(awsExports);
const client = generateClient();

function MenuButtonStrain( props)  {

  //function snotify(note) {toast.success(note);};
  //function inotify(note) {toast.info(note)};
  function enotify(note) {toast.error(note)};
  //function wnotify(note) {toast.warning(note)};

    const client = generateClient();


    const [state, setState] = useState("--");
    const [list, setList] = useState("--");


//console.log(props.likeList);
//console.log(props.uname)

    function stateChange(f) {
        setState(f);
        props.funct(f,props.bugDesc, props.bugType);
    //   to set loader
    
  };



    async function handleButton() {
        
    
     console.log('Menu button pressed')
          stateChange(props.bugId);
    };


    const likes = props.likes;
    var tmpLikes = String(likes).split(':');
    const dislikes = props.dislikes;
    var tmpDislikes = String(dislikes).split(':');

    if ( tmpLikes.includes(props.bugDesc) ) { var tmpC = '!!'; var tmpColor = "white"  } else { 
      
      if ( tmpDislikes.includes(props.bugDesc) ) { var tmpC = 'X' ; tmpColor = "white"  } else { 
        tmpC = '=' ; tmpColor = "grey"  
      };
        };
    //console.log(tmpLikes); 


    return (
            <>

<div style={{boxShadow: '0 0 0px 0px green',  display:"flex", rowGap:'0vw', columnGap:'0vw', minHeight:'35px', flexDirection:'column' , justifyItems:'center' , justifyContent:'center' ,  alignItems:'center', textAlign:'center'}}>
<span>
<button className='product' style={{borderWidth:'0px',backgroundColor: 'inherit', color:tmpColor, minHeight:'35px',minWidth:'35px', paddingTop:'0px', paddingBottom:'0px', paddingLeft:'0px', paddingRight:'0px'}} onClick={handleButton} >{tmpC}</button>            
</span>
 </div>

            </>
    );
};

export default MenuButtonStrain;