import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ComponentPropsToStylePropsMap, withAuthenticator  } from '@aws-amplify/ui-react';
//import Chatbot from 'react-chatbot-kit';
//import ActionProvider from './WAIDActionProvider';
//import MessageParser from './WAIDMessageParser';
//import { createChatBotMessage } from "react-chatbot-kit";
import {
  Flex,
//  Heading,
//  Image,
Button,
  View,
} from "@aws-amplify/ui-react";
import { fetchAuthSession } from 'aws-amplify/auth';
import { generateClient } from 'aws-amplify/api';
import { createTodo, updateTodo, deleteTodo} from '../graphql/mutations';
import { listTodos, getTodo, todosByDescription, ByDescription } from '../graphql/queries';

import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import ButtonMui from "@mui/material/Button";
import RemoveButtonStrain from "./RemoveButtonStrain";
import LikeButtonStrain from "./LikeButtonStrain";
import DislikeButtonStrain from "./DislikeButtonStrain";
import MenuButtonStrain from "./MenuButtonStrain";
import EditButtonStrain from "./EditButtonStrain";
import { getCurrentUser } from 'aws-amplify/auth';
import { toast } from "react-toastify";

import logo from '../logo.svg';
import appConfig from '../AppConfig';




function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    let id = setInterval(() => {
      savedCallback.current();
    }, delay);
    return () => clearInterval(id);
  }, [delay]);
};


function Favorites({signOut,user}) {


 // function snotify(note) {toast.success(note);};
  function inotify(note) {toast.info(note)};
  //function enotify(note) {toast.error(note)};
  function wnotify(note) {toast.warning(note)};

  //AutoUpdate from backend
 var tmpcnt = 10000;
 useInterval(() => {
   getUserFav(currentType);
   //console.log('fetch')
 }, tmpcnt);

    function snotify(note) {toast.success(note);};
    //function inotify(note) {toast.info(note)};
    //function enotify(note) {toast.error(note)};
    function wnotify(note) {toast.warning(note)};
  const ver = appConfig.name;
  
  const [state1, setState1] = useState("");
  const [scount, setScount] = useState("");
  const [mcount, setMcount] = useState(0);
  const [loading, setLoading] = useState("1");

  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [hist, setHist] = useState("");
  const [comment, setComment] = useState("");
    const [comment1, setComment1] = useState("");
    const [comment2, setComment2] = useState("");
    const [comment3, setComment3] = useState("");
    const [comment4, setComment4] = useState("");
    const [comment5, setComment5] = useState("");
    const [comment6, setComment6] = useState("");
  const [strainList, setStrainList] = useState([]);
  const [likeList, setLikeList] = useState([]);
  const [dislikeList, setDislikeList] = useState([]);

  const [nameList, setNameList] = useState([]);
  const [missingList, setMissingList] = useState([]);
  
  const [menuStrainId, SetMenuStrainId] = useState("");
  const [menuStrainName, SetMenuStrainName] = useState("");

  const [open1, setOpen1] = useState(false);
  const handleToClose1 = () => {
      setOpen1(false);  
  };
  const handleClickToOpen1 = () => {
    setOpen1(true);
  };

  const [open2, setOpen2] = useState(false);
  const handleToClose2 = () => {
      setOpen2(false);  
  };
  const handleClickToOpen2 = () => {
      setOpen2(true);
  };

  const [open3, setOpen3] = useState(false);
  const handleToClose3 = () => {
      setOpen3(false);  
  };
  const handleClickToOpen3 = (strainId,strainName) => {
    SetMenuStrainId(strainId);
    SetMenuStrainName(strainName);
    setOpen3(true);
  };


  const [open4, setOpen4] = useState(false);
  const handleToClose4 = () => {
      setOpen4(false);  
  };
  const handleClickToOpen4 = () => {
    setOpen4(true);
  };
  const handleSubmit4 = (e) => {
    e.preventDefault();
    //👇🏻 calls the function
      updateEffect();
      handleToClose4();
  };
  
  const [open5, setOpen5] = useState(false);
  const handleToClose5 = () => {
      setOpen5(false);  
  };
  const handleClickToOpen5 = () => {
    setOpen5(true);
  };
  const handleSubmit5 = (e) => {
    e.preventDefault();
    //👇🏻 calls the function
      updateNote();
      handleToClose5();
  };
  
  
  const [currentType, setCurrentType] = useState("flower");

const [uname, setUname] = useState("");

  const client = generateClient();


  useEffect(() => {
   // document.title = ver;
   // getUser();
    getUserFav();
  
    }, []);
  




	const getUserFav = async () => {

    //Get Likes/Dislikes
    const { username, userId} = await getCurrentUser();
    //Set products user
    setUname(username);

    try {
      //Get user doc
     const oneTodo = await client.graphql({
             query: todosByDescription,
             variables: { name: username }
               });
               console.log("Products User:" + username);    
         //  console.log(oneTodo);
                 //Get Current L/D
                    console.log("Getting L/D");
                    //FLOWER
                    var tmpListL= oneTodo.data.todosByDescription.items[0].likes;
                    var tmpListD= oneTodo.data.todosByDescription.items[0].dislikes;
            //        console.log("L:" + tmpListL);
            //        console.log("D:" + tmpListD);
                    var tmpList2L = [tmpListL];
                    var tmpList2D = [tmpListD];
           //         console.log(tmpList2L);
           //         console.log(tmpList2D);
                    setLikeList(tmpList2L);
                    setDislikeList(tmpList2D);

                    //CARTS
                    var tmpListL= oneTodo.data.todosByDescription.items[0].cartLikes;
                    var tmpListD= oneTodo.data.todosByDescription.items[0].cartDislikes;
            //        console.log("L:" + tmpListL);
            //        console.log("D:" + tmpListD);
                    var tmpCartList2L = [tmpListL];
                    var tmpCartList2D = [tmpListD];
           //         console.log(tmpList2L);
           //         console.log(tmpList2D);
                    setLikeList(tmpCartList2L);
                    setDislikeList(tmpCartList2D);

                    //CONC
                    var tmpListL= oneTodo.data.todosByDescription.items[0].concLikes;
                    var tmpListD= oneTodo.data.todosByDescription.items[0].concDislikes;
            //        console.log("L:" + tmpListL);
            //        console.log("D:" + tmpListD);
                    var tmpConcList2L = [tmpListL];
                    var tmpConcList2D = [tmpListD];
           //         console.log(tmpList2L);
           //         console.log(tmpList2D);
                    setLikeList(tmpConcList2L);
                    setDislikeList(tmpConcList2D);


                    //EDD
                    var tmpListL= oneTodo.data.todosByDescription.items[0].eddLikes;
                    var tmpListD= oneTodo.data.todosByDescription.items[0].eddDislikes;
            //        console.log("L:" + tmpListL);
            //        console.log("D:" + tmpListD);
                    var tmpEddList2L = [tmpListL];
                    var tmpEddList2D = [tmpListD];
           //         console.log(tmpList2L);
           //         console.log(tmpList2D);
                    setLikeList(tmpEddList2L);
                    setDislikeList(tmpEddList2D);


         } catch (error) {
             console.log('error produts get user' , error);








             try {
              //Create new user
              console.log('creating new user record')
                const result = await client.graphql({
              query: createTodo,
              variables: {
                input: {
                 name: username ,
                 description: 'user doc' ,
                 settings: appConfig.settings,
                 likes: appConfig.likes,
                 dislikes: appConfig.dislikes,
                 cartLikes: appConfig.likes,
                 cartDislikes: appConfig.dislikes,
                 concLikes: appConfig.likes,
                 concDislikes: appConfig.dislikes,
                 eddLikes: appConfig.likes,
                 eddDislikes: appConfig.dislikes,
                 history: appConfig.history
                }
              }
            });



            console.log('user created');
          } catch (e) {
            console.log('Create new user Failed(Products): ', e);
          };


           };


                

                //get data for each like and display
                console.log('loading favorites for user' )

                try {
              

                  if (tmpList2L[0] === 'none') {
                    console.log('No Favorites');
                    setStrainList(  [JSON.parse('{"idx":"0","name":"none","description":"none","type":"indica","thc":"0", "cbd":"0", "effect":"none", "flavor":"none"}') ] );
                    setLoading('2');
                  } else {

                var arr = [];
                var tmpMissing = ['Missing Strains: '];
        //          console.log("TL:" + tmpListL);
              //    var tmpArray = ["Alien Rock Candy","Animal Mints"];

                    if (currentType === 'eddible') {

               

                //EDDIBLE
                var tmpArray = String(tmpEddList2L).split(':');
                //     console.log('len:' + tmpArray.length)
                        for (let i = 0; i < tmpArray.length; i++) {
                        var favTodo3 = await client.graphql({
                          query: ByDescription,
                          variables: { description: tmpArray[i] }
                            }); 
                  //          console.log('getting ' + tmpArray[i])
                            var obj = {};
                            obj = favTodo3.data.ByDescription.items[0];
                            console.log(obj);
                           if (obj == null) {
                            console.log('missing strain record!!! ' + tmpArray[i]);
                            tmpMissing = [tmpMissing , tmpArray[i] ];
                           } else {
                            arr.push(obj);
                           };     
                      };

              }  else if (currentType === 'cartridge') {



                //CARTRIDGE
                var tmpArray = String(tmpCartList2L).split(':');
                //     console.log('len:' + tmpArray.length)
                        for (let i = 0; i < tmpArray.length; i++) {
                        var favTodo3 = await client.graphql({
                          query: ByDescription,
                          variables: { description: tmpArray[i] }
                            }); 
                  //          console.log('getting ' + tmpArray[i])
                            var obj = {};
                            obj = favTodo3.data.ByDescription.items[0];
                            console.log(obj);
                           if (obj == null) {
                            console.log('missing strain record!!! ' + tmpArray[i]);
                            tmpMissing = [tmpMissing , tmpArray[i] ];
                           } else {
                            arr.push(obj);
                           };     
                      };

              } else if ( currentType === 'concentrate'){

                
                //CONCENTRATE
                var tmpArray = String(tmpConcList2L).split(':');
                //     console.log('len:' + tmpArray.length)
                        for (let i = 0; i < tmpArray.length; i++) {
                        var favTodo3 = await client.graphql({
                          query: ByDescription,
                          variables: { description: tmpArray[i] }
                            }); 
                  //          console.log('getting ' + tmpArray[i])
                            var obj = {};
                            obj = favTodo3.data.ByDescription.items[0];
                            console.log(obj);
                           if (obj == null) {
                            console.log('missing strain record!!! ' + tmpArray[i]);
                            tmpMissing = [tmpMissing , tmpArray[i] ];
                           } else {
                            arr.push(obj);
                           };     
                      };

              } else {

                //FLOWER
                var tmpArray = String(tmpList2L).split(':');
                //     console.log('len:' + tmpArray.length)
                        for (let i = 0; i < tmpArray.length; i++) {
                        var favTodo3 = await client.graphql({
                          query: ByDescription,
                          variables: { description: tmpArray[i] }
                            }); 
                  //          console.log('getting ' + tmpArray[i])
                            var obj = {};
                            obj = favTodo3.data.ByDescription.items[0];
                            console.log(obj);
                           if (obj == null) {
                            console.log('missing strain record!!! ' + tmpArray[i]);
                            tmpMissing = [tmpMissing , tmpArray[i] ];
                           } else {
                            arr.push(obj);
                           };     
                      };
                      


              };


                  console.log(arr);
                   setStrainList(arr);
                   setScount(arr.length);
                   setMissingList(tmpMissing);
                   setMcount(tmpMissing - 1);
                  setLoading('0');


                
            console.log('favorites loaded');

          };
            
          } catch (e) {
            console.log('Error loading favorites: ', e);
          };

	}; 



  async function handlePnote() {
    handleToClose3();
    handleClickToOpen5(true);

  };


  async function updateNote() {
    alert('disabled (554)')


  };


  async function handlePeff() {
    handleToClose3();
    handleClickToOpen4(true);


  };

  async function updateEffect() {
    alert('disabled (553)')


  };


  const handleChangeCurrentType = (e) => {
    //👇🏻 calls the function
      setCurrentType(e);
      getUserFav();
  };

  





const handleDislikeListChange = (e) => {
//console.log('DisLike List change called');
//console.log('e:' + e);
const oldLList = String(likeList);
const newLList = oldLList.split(':');
const oldDLList = String(dislikeList);
const newDLList = oldDLList.split(':');
//console.log('newDLList' + newDLList);
if (newDLList.includes(menuStrainName)) {
// console.log('dup');
wnotify('Error: Duplicate dislike');
SetMenuStrainName("");
SetMenuStrainId("");
handleToClose3();
} else {
if (newLList.includes(menuStrainName)) {
//Already in Likes
wnotify('Error: Strain already on likes list');
SetMenuStrainName("");
SetMenuStrainId("");
handleToClose3();
} else {

//Update and Save User Doc
if (oldDLList === "none") {
updateDislikes( menuStrainName );

} else {
updateDislikes( oldDLList + ":" + menuStrainName  );

};
snotify('Strain added to dislikes list');
SetMenuStrainName("");
SetMenuStrainId("");
handleToClose3();
};
};
};



 async function updateDislikes(props) {


if (currentType === 'cartridge') {


//CARTRIDGE
setDislikeList(props);
//apply New DisLikeList  
try {
       
  //Get user doc
  const oneTodo = await client.graphql({
  query: todosByDescription,
  variables: { name: uname }
  });
//      console.log("updateDisLikesDoc User:" + uname);    
//       console.log(oneTodo);

  const todoDetails = {
      id: oneTodo.data.todosByDescription.items[0].id,
      cartDislikes: props
  };      
  const updatedTodo = await client.graphql({
    query: updateTodo,
    variables: { input: todoDetails }
  });
//    console.log('dislikes saved');       
//   console.log(updatedTodo);
 
} catch (e) {
  console.log('save dislike error: ', e);
};


} else if (currentType === 'concentrate' ) {



//CONCENTRATE
setDislikeList(props);
//apply New DisLikeList  
try {
       
  //Get user doc
  const oneTodo = await client.graphql({
  query: todosByDescription,
  variables: { name: uname }
  });
//      console.log("updateDisLikesDoc User:" + uname);    
//       console.log(oneTodo);

  const todoDetails = {
      id: oneTodo.data.todosByDescription.items[0].id,
      concDislikes: props
  };      
  const updatedTodo = await client.graphql({
    query: updateTodo,
    variables: { input: todoDetails }
  });
//    console.log('dislikes saved');       
//   console.log(updatedTodo);
 
} catch (e) {
  console.log('save dislike error: ', e);
};


 } else if (currentType === 'eddible' ) { 


//EDDIBLE
setDislikeList(props);
//apply New DisLikeList  
try {
       
  //Get user doc
  const oneTodo = await client.graphql({
  query: todosByDescription,
  variables: { name: uname }
  });
//      console.log("updateDisLikesDoc User:" + uname);    
//       console.log(oneTodo);

  const todoDetails = {
      id: oneTodo.data.todosByDescription.items[0].id,
      eddDislikes: props
  };      
  const updatedTodo = await client.graphql({
    query: updateTodo,
    variables: { input: todoDetails }
  });
//    console.log('dislikes saved');       
//   console.log(updatedTodo);
 
} catch (e) {
  console.log('save dislike error: ', e);
};

} else {


//FLOWER
setDislikeList(props);
//apply New DisLikeList  
try {
       
  //Get user doc
  const oneTodo = await client.graphql({
  query: todosByDescription,
  variables: { name: uname }
  });
//      console.log("updateDisLikesDoc User:" + uname);    
//       console.log(oneTodo);

  const todoDetails = {
      id: oneTodo.data.todosByDescription.items[0].id,
      dislikes: props
  };      
  const updatedTodo = await client.graphql({
    query: updateTodo,
    variables: { input: todoDetails }
  });
//    console.log('dislikes saved');       
//   console.log(updatedTodo);
 
} catch (e) {
  console.log('save dislike error: ', e);
};

};




 };



function goBack() {
  navigate(`/products`);
};

function goHome() {
  navigate(`/`);
};


if (loading === '1') { var tmpHideA = true; var tmpHideB = false ; var tmpHideC = true } 
else if (loading === '2') { tmpHideA = true; tmpHideB = true ; tmpHideC = false}
else { tmpHideA = false; tmpHideB = true; tmpHideC = true};

if (mcount === 0 ) {var tmpHideM = true} else {tmpHideM = false};

  return (
    <>


<div className="Headder"       
        >
          <span style={{ }}>
        {ver}
        </span>

 

        <span style={{paddingLeft:'1vw'}} >

        <span style={{ paddingRight:"4px",fontSize:"10px"}} >
 
        </span>
        
        
        

        
        <button className="HeadderButton"onClick={goHome}>Back</button>
        
        
        </span>


        </div>
   
    



<div className = "main2">

<div className = "title">
Favorite 
<span style={{paddingLeft:'4px'}}>
<select name="mode" onChange={(e) => handleChangeCurrentType(e.target.value)} >
  <option value="flower">Flower</option>
  <option value="cartridge">Cartridge</option>
  <option value="concentrate">Concentrate</option>
  <option value="eddible">Eddible</option>
</select>
</span>
<span style={{paddingLeft:'4px'}}>
Strains ({scount})
</span>

</div>


<div hidden = {tmpHideA} className = "body-products" style={{minHeight:'101vh'}}>

<div className='thread__container'>
                    {strainList.map((thread,idx) => (

                     <>

                        <div  style={{	boxShadow: '0 0 0px 0px yellow', display: "flex",  backgroundColor:'#4c663b', flexDirection:'column' , minWidth:'90vw', maxWidth:'90vw',  margin:'5px', borderRadius:"3px"}} key={`song${idx}`}>

                        <div style={{ boxShadow: '0 0 0px 0px yellow',  display:"flex",backgroundColor:'', rowGap:'0vw', columnGap:'1vw', flexDirection:'row' , justifyItems:'flex-start' , justifyContent:'space-between', paddingTop:'1px', minWidth:'90vw', maxWidth:'90vw', minHeight:'45px', alignItems:"center", textAlign:'center'}}>
                        
                        <div style={{boxShadow: '0 0 0px 0px green',  display:"flex", flexDirection:'column' ,backgroundColor:'', minWidth:'22vw',maxWidth:'22vw', textAlign:'left', paddingLeft:'1.7vw', color:'white', fontSize:'calc(10px + .5vw)', flexWrap:'wrap', rowGap:'15px', columnGap:'15px'}}>{thread.description}</div>
                        
                        <div style={{boxShadow: '0 0 0px 0px green',  display:"flex", flexDirection:'row' ,backgroundColor: thread.color  , minWidth:'10vw',maxWidth:'10vw', paddingLeft:'6px',paddingRight:'6px', paddingTop:'10px', paddingBottom:'10px', textAlign:'left', color:'white',  fontSize:'calc(8px + .5vw)'}}> {thread.type.charAt(0).toUpperCase() + thread.type.slice(1)}</div>
                        <div style={{boxShadow: '0 0 0px 0px green',  display:"flex", flexDirection:'row' ,backgroundColor:"", minWidth:'17vw',maxWidth:'17vw', textAlign:'left', color:'white',  fontSize:'calc(8px + .5vw)'}}> THC:{thread.thc} CBD:{thread.cbd}  </div>

                        <div style={{boxShadow: '0 0 0px 0px green',  display:"flex", rowGap:'2vw', columnGap:'2vw', flexDirection:'row' , justifyItems:'center' , justifyContent:'center' ,  alignItems:'center', textAlign:'center'}}>
                      
                        <div style={{boxShadow: '0 0 0px 0px green',  display:"flex", flexDirection:'row' , backgroundColor:'', minWidth:'22vw',maxWidth:'22vw', textAlign:'left', color:'white',  fontSize:'calc(8px + .5vw)'}}> Effect:{thread.effect} Flavor:{thread.flavor}  </div>
                        
                        <div style={{boxShadow: '0 0 0px 0px green',  display:"flex", flexDirection:'column' , backgroundColor:'#4e4e4e', minWidth:'8vw',maxWidth:'8vw', minHeight:'35px' , maxHeight:'35px' , textAlign:'left', color:'white',  fontSize:'calc(8px + .5vw)'}}>   
                        
                        <MenuButtonStrain 
                               bugId={thread.id}
                               bugDesc={thread.description}
                               bugType={thread.type}
                               funct={handleClickToOpen3} 
                               likes={likeList}
                               dislikes={dislikeList}
                        />

                        </div>
            
                    
                      
                            </div>
                    

                            <span style={{paddingRight:'4px'}}>
              
                      </span>  

                        </div>
                       

                    </div>

                      
                 </>       

    )
  
  )}



                </div>

                </div>












                <div style={{}}> 
      <Dialog open={open3} onClose={handleToClose3} >
        <DialogTitle style={{backgroundColor:'#242424', color:'#d8d8d8', borderBottomStyle:'none', borderTopStyle:'solid',borderLeftStyle:'solid',borderRightStyle:'solid', borderWidth:'2px', borderColor:'#4c663b'}}>
          {menuStrainName}
        </DialogTitle>
        <DialogContent style={{backgroundColor:'#242424', color:'white', borderStyle:'none', borderBottomStyle:'solid', borderTopStyle:'none',borderLeftStyle:'solid',borderRightStyle:'solid', borderWidth:'2px', borderColor:'#4c663b'}}>
          <DialogContentText>
            
            <Flex
              direction='column'
            >

<Button style={{minWidth:'100px', backgroundColor:'', color:"#a0a0a0"}} onClick={handlePeff} >Effect</Button>
<Button style={{minWidth:'100px', backgroundColor:'', color:"#a0a0a0"}} onClick={handlePnote} >Note</Button>
                      <Button style={{minWidth:'100px', backgroundColor:'', color:"#a0a0a0"}} onClick={handleDislikeListChange} >Dislike</Button>
             
                      <Button style={{minWidth:'100px', backgroundColor:'', color:"#a0a0a0"}} onClick={handleToClose3}>Close</Button>
                   
      
            </Flex>

              


          </DialogContentText> 
        </DialogContent>

      </Dialog>
    </div>








    <div stlye={{}}> 
    <Dialog open={open4} onClose={handleToClose4}>
        <DialogTitle style={{backgroundColor:'#242424', color:'#d8d8d8', borderBottomStyle:'none', borderTopStyle:'solid',borderLeftStyle:'solid',borderRightStyle:'solid', borderWidth:'2px', borderColor:'#4c663b'}}>
          Edit Personal Effect
        </DialogTitle>
        <DialogContent style={{backgroundColor:'#242424', color:'#d8d8d8', borderBottomStyle:'none', borderTopStyle:'none',borderLeftStyle:'solid',borderRightStyle:'solid', borderWidth:'2px', borderColor:'#4c663b'}} >
          <DialogContentText style={{backgroundColor:'#242424', color:'#d8d8d8'}}>
    

    <input
        value={comment6}
        onChange={(e) => setComment4(e.target.value)}
        type='text'
        name='comment4'
        id='comment4'
        className='inp-field'
        style={{width:'200px'}}
    />

<Flex 
          direction="column"
          gap = "10px"
          paddingTop='15px'
          >
              <Button  style={{backgroundColor:'', color:"#80b35e" , fontWeight:'normal'}} onClick={handleSubmit4}  >Save</Button>
              <Button  style={{backgroundColor:'', color:"#a0a0a0" , fontWeight:'normal'}} onClick={handleToClose4}  >Cancel</Button>
              </Flex>

          </DialogContentText> 
        </DialogContent>
       
      </Dialog>
    </div>



    <div stlye={{}}> 
    <Dialog open={open5} onClose={handleToClose5}>
        <DialogTitle style={{backgroundColor:'#242424', color:'#d8d8d8', borderBottomStyle:'none', borderTopStyle:'solid',borderLeftStyle:'solid',borderRightStyle:'solid', borderWidth:'2px', borderColor:'#4c663b'}}>
          
             Edit Personal Note

        </DialogTitle>
        <DialogContent style={{backgroundColor:'#242424', color:'#d8d8d8', borderBottomStyle:'solid', borderTopStyle:'none',borderLeftStyle:'solid',borderRightStyle:'solid', borderWidth:'2px', borderColor:'#4c663b'}}>
          <DialogContentText>
            
          <Flex 
          direction="column"
          textAlign="center"
          alignItems="center"
 
          minWidth="200px"
            
          minHeight="55vh"
          gap="0.5vw"
          paddingTop="1vw"
          fontSize="14px"
          >
            
			<form  onSubmit={handleSubmit5}>
    
        <textarea
            
            rows={17}
			cols={40}
           
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            type='text'
            name='comment'
            
        />

             <Flex 
          direction="column"
          gap = "10px"
          paddingTop='15px'
          >
              <Button  style={{backgroundColor:'', color:"#80b35e" , fontWeight:'normal'}} onClick={handleSubmit5}  >Save</Button>
              <Button  style={{backgroundColor:'', color:"#a0a0a0" , fontWeight:'normal'}} onClick={handleToClose5}  >Cancel</Button>
              </Flex>
		</form>

          
            
            </Flex>


          </DialogContentText> 
        </DialogContent>
        
      </Dialog>
      </div>





<div hidden = {tmpHideB} className = "loadingPage" style={{minHeight:'101vh'}} >
  
  <img src={logo} className="App-logo" alt="logo" />

</div>




<div hidden = {tmpHideC} className = "body-products" style={{minHeight:'101vh'}}>

      <span style={{fontSize:"20px", color:"white", fontWeight:"normal"}}>
        <br></br><br></br><br></br>
        No Favorites
        </span>
</div>



<div hidden = {tmpHideM} className = "body-products">
      {missingList}
</div>

    

  </div>






    </>
);

};

export default withAuthenticator(Favorites);
