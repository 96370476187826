/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTodo = /* GraphQL */ `
  query GetTodo($id: ID!) {
    getTodo(id: $id) {
      id
      name
      description
      type
      thc
      cbd
      effect
      flavor
      history
      settings
      location
      likes
      dislikes
      cartLikes
      cartDislikes
      concLikes
      concDislikes
      eddLikes
      eddDislikes
      color
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTodos = /* GraphQL */ `
  query ListTodos(
    $filter: ModelTodoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTodos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        type
        thc
        cbd
        effect
        flavor
        history
        settings
        location
        likes
        dislikes
        cartLikes
        cartDislikes
        concLikes
        concDislikes
        eddLikes
        eddDislikes
        color
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const todosByDescription = /* GraphQL */ `
  query TodosByDescription(
    $name: ID!
    $description: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTodoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    todosByDescription(
      name: $name
      description: $description
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        type
        thc
        cbd
        effect
        flavor
        history
        settings
        location
        likes
        dislikes
        cartLikes
        cartDislikes
        concLikes
        concDislikes
        eddLikes
        eddDislikes
        color
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ByDescription = /* GraphQL */ `
  query ByDescription(
    $description: String!
    $sortDirection: ModelSortDirection
    $filter: ModelTodoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ByDescription(
      description: $description
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        type
        thc
        cbd
        effect
        flavor
        history
        settings
        location
        likes
        dislikes
        cartLikes
        cartDislikes
        concLikes
        concDislikes
        eddLikes
        eddDislikes
        color
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
