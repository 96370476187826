import React, { useState} from "react";

import { deleteTodo} from '../graphql/mutations';
import { generateClient } from 'aws-amplify/api';

function RemoveButtonFB( props)  {


    const client = generateClient();


    const [state, setState] = useState("--");




    function stateChange(f) {
        setState(f);
        props.funct(f);
    //   to set loader
  };




    async function handleRemoveBug() {
        


        try {
     

     const todoDetails = {
        id: props.bugId       
      };

      const deletedTodo = await client.graphql({
        query: deleteTodo,
        variables: { input: todoDetails }
      });


          alert('Feedback Report Deleted');
stateChange('1');
         
         
        } catch (error) {
            console.log('error delete feedback 101' , error);
          };

          
    };



if (props.bugId==='none') {
  var tmpHide = true;
} else {
  tmpHide = false;
};


    return (
            <span hidden={tmpHide} >
            <button style={{borderWidth:'0px',backgroundColor:'red', color:'white'}} onClick={handleRemoveBug} >X</button> 
            </span>
    );
};

export default RemoveButtonFB;