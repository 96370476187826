import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { withAuthenticator  } from '@aws-amplify/ui-react';
//import Chatbot from 'react-chatbot-kit';
//import ActionProvider from './WAIDActionProvider';
//import MessageParser from './WAIDMessageParser';
//import { createChatBotMessage } from "react-chatbot-kit";
import {
  Flex,
//  Heading,
//  Image,
Button,
  View,
} from "@aws-amplify/ui-react";
import { fetchAuthSession } from 'aws-amplify/auth';
import { generateClient } from 'aws-amplify/api';
import { createTodo, updateTodo, deleteTodo} from '../graphql/mutations';
import { listTodos, getTodo, todosByDescription } from '../graphql/queries';

import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import ButtonMui from "@mui/material/Button";
import RemoveButtonBug from "./RemoveButtonBug";
import RemoveButtonLD from "./RemoveButtonLD";
import RemoveButtonFB from "./RemoveButtonFB";
import strain_db from '../strain_db_1280';
import { toast } from "react-toastify";
import appConfig from '../AppConfig';
import { create } from "@mui/material/styles/createTransitions";




function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    let id = setInterval(() => {
      savedCallback.current();
    }, delay);
    return () => clearInterval(id);
  }, [delay]);
};


function Settings({signOut,user}) {

  const ver = appConfig.name;



    //AutoUpdate from backend
 var tmpcnt = 10000;
 useInterval(() => {
  getSettingsAndHistDocAndLD();
   //console.log('fetch')
 }, tmpcnt);


  
    function snotify(note) {toast.success(note);};
    //function inotify(note) {toast.info(note)};
    //function enotify(note) {toast.error(note)};
    function wnotify(note) {toast.warning(note)};

  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [hist, setHist] = useState("");
  const [comment, setComment] = useState("");

  const [bugList, setBugList] = useState([]);
  const [likeList, setLikeList] = useState([]);
  const [dislikeList, setDislikeList] = useState([]);
  const [splLikeList, setSplLikeList] = useState([]);
  const [splDislikeList, setSplDislikeList] = useState([]);

  const [open1, setOpen1] = useState(false);
  const handleToClose1 = () => {
      setOpen1(false);  
  };
  const handleClickToOpen1 = () => {
    setOpen1(true);
  };

  const [open2, setOpen2] = useState(false);
  const handleToClose2 = () => {
      setOpen2(false);  
  };
  const handleClickToOpen2 = () => {
    getBugs();
    setOpen2(true);
  };

  const [open3, setOpen3] = useState(false);
  const handleToClose3 = () => {
      setOpen3(false);  
  };
  const handleClickToOpen3 = () => {
    setOpen3(true);
  };

  const [open4, setOpen4] = useState(false);
  const handleToClose4 = () => {
      setOpen4(false);  
  };
  const handleClickToOpen4 = () => {
    setOpen4(true);
  };

  const [open5, setOpen5] = useState(false);
  const handleToClose5 = () => {
      setOpen5(false);  
  };
  const handleClickToOpen5 = () => {
    setOpen5(true);
  };

  const [open6, setOpen6] = useState(false);
  const handleToClose6 = () => {
      setOpen6(false);  
  };
  const handleClickToOpen6 = () => {
    setOpen6(true);
  };

  const handleSubmit6 = (e) => {
    e.preventDefault();
    //👇🏻 calls the function
      modifyMaxItems();
      handleToClose6();
};


const [open7, setOpen7] = useState(false);
const handleToClose7 = () => {
    setOpen7(false);  
};
const handleClickToOpen7 = () => {
  setOpen7(true);
};

const handleSubmit7 = (e) => {
  e.preventDefault();
  //👇🏻 calls the function
    
    handleToClose7();
};

const [open8, setOpen8] = useState(false);
const handleToClose8 = () => {
    setOpen8(false);  
};
const handleClickToOpen8 = () => {
  setOpen8(true);
};

const handleSubmit8 = (e) => {
  e.preventDefault();
  //👇🏻 calls the function
    addFeedback();
    handleToClose8();
};


const [open9, setOpen9] = useState(false);
const handleToClose9 = () => {
    setOpen9(false);  
};
const handleClickToOpen9 = () => {
  getFeedback();
  setOpen9(true);
};

const handleSubmit9 = (e) => {
  e.preventDefault();
  //👇🏻 calls the function
    
    handleToClose9();
};




const handleLikesChange = (e) => {
  //console.log('Like List change called');
  //onsole.log('e:' + e);

  const oldList = String(likeList);
  const newList = oldList.split(':');
  function canVote(x) {
    return x !== e;
  };
  let filtered = newList.filter(canVote);
 //console.log('Modified List' + filtered);
 updateLikes( String(filtered) );
 setLikeList(String(filtered));
 snotify('Strain removed from likes list')
};


const handleDislikesChange = (e) => {
  //console.log('Dislike List change called');
  //console.log('e:' + e);
  const oldList = String(dislikeList);
  const newList = oldList.split(':');

  function canVote(x) {
    return x !== e;
  };
  let filtered = newList.filter(canVote);
 //console.log('Modified List' + filtered);
 updateDislikes( String(filtered) );
 setDislikeList(String(filtered));
 snotify('Strain removed from dislikes list')
};

const [settingsList, setSettingsList] = useState("");
const [comment1, setComment1] = useState("");
const [comment6, setComment6] = useState("");


  const client = generateClient();


  useEffect(() => {
  //  document.title = ver;
    getUser();
    getSettingsAndHistDocAndLD();
    }, []);
  

   async function getUser() {
   try {
    const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
    //console.log(idToken.payload.email)
    setEmail(idToken.payload.email);
    //setHideChat(false)
  } catch (err) {
    console.log(err);
  }};
  
  async function modifyMaxItems() {
    if (comment6 !== '') {


      if ( isNaN(comment6) || comment6 > 10 || comment6 < 0)  {  
        //Max Items is not a number, blank or out f range
          wnotify('Error: Max Items must be a number from 1 to 10');  
      } else {
        //Modify Max Items and Update Settings Doc
        settingsList.maxlist=comment6;
        updateSettings();

        //Reset Max Items Form (optional)
        //setComment6('');

        }; 
    






    } else {
    //Max Items is blank
    wnotify('Error: Max Items can not be blank'); 
    };
  };


	const getBugs = async () => {


    try {
    
      const todos = await client.graphql({
        query: todosByDescription,
        variables: { name: 'bug' }
      });

   // console.log('get bugs');
  //   console.log(todos);

      if (todos.data.todosByDescription.items.length === 0) {
        setBugList([{"id":"none", "createdAt":"","history":""}]);
      } else {
        const todoList = todos.data.todosByDescription.items;        
      //console.log('bug list:' + todoList);
        setBugList(todoList);
      };
     
    } catch (e) {
      console.log('getBugs-settings failed: ', e);
  
    };
  
	}; 


	const getFeedback = async () => {


    try {
    
      const todos = await client.graphql({
        query: todosByDescription,
        variables: { name: 'feedback' }
      });

   // console.log('get feedback');
  //   console.log(todos);

      if (todos.data.todosByDescription.items.length === 0) {
        setBugList([{"id":"none","description":"none", "createdAt":"","history":""}]);
      } else {
        const todoList = todos.data.todosByDescription.items;        
      //console.log('FB list:' + todoList);
        setBugList(todoList);
      };
     
    } catch (e) {
      console.log('getFeedback-settings failed: ', e);
  
    };
  
	}; 


  async function getSettingsAndHistDocAndLD() {
   // console.log('getting settings/hist doc')
    try {
      //Get user doc
      const oneTodo = await client.graphql({
      query: todosByDescription,
      variables: { name: user.username }
        });
        console.log("SettingsDoc User:" + user.username);    
    //console.log(oneTodo);

    //Get and Clean Up Conversation History
      var tmpHist= oneTodo.data.todosByDescription.items[0].history;

      var tmp2 = tmpHist.replaceAll('{"messages":[',"");
      var tmp3 = tmp2.replaceAll('{"message":',"");
      var tmp4 = tmp3.replaceAll('"type":',"");
      var tmp5 = tmp4.replaceAll('"loading":false}',"");
      var tmp6 = tmp5.replaceAll('"id":'," ");
      setHist(tmp6);

      


      //Get Current Settings
      //console.log("Getting Settings");
      var tmpList= oneTodo.data.todosByDescription.items[0].settings;
      //console.log(tmpList);
      var tmpList2 = JSON.parse(tmpList);
      //console.log(tmpList2);
      setSettingsList(tmpList2);

                      //Get current L/D
                      var tmpListL= oneTodo.data.todosByDescription.items[0].likes;
                       var tmpListD= oneTodo.data.todosByDescription.items[0].dislikes;
        //               console.log("L:" + tmpListL);
        //              console.log("D:" + tmpListD);
                  //     var tmpList2L = JSON.parse(tmpListL);
                  //     var tmpList2D = JSON.parse(tmpListD);
       //                console.log(tmpList2L);
       //              console.log(tmpList2D);
                       setLikeList(tmpListL);
                       setDislikeList(tmpListD);
                       setSplLikeList( String(tmpListL).split(':'));
                       setSplDislikeList( String(tmpListD).split(':'));

      } catch (e) {
      console.log('Get User Settings Doc Failed: ', e);
    }
  };


  async function defaultSettings() {
   //apply Default Settings  
   console.log('apply defualt settings');
            try {
          
            //Get user doc
            const oneTodo = await client.graphql({
            query: todosByDescription,
            variables: { name: user.username }
            });
            console.log("defaultSettingsDoc User:" + user.username);    
            console.log(oneTodo);

            const todoDetails = {
                id: oneTodo.data.todosByDescription.items[0].id,
                settings: appConfig.settings,
                likes: appConfig.likes,
                dislikes: appConfig.dislikes,
                cartLikes: appConfig.likes,
                cartDislikes: appConfig.dislikes,
                concLikes: appConfig.likes,
                concDislikes: appConfig.dislikes,
                eddLikes: appConfig.likes,
                eddDislikes: appConfig.dislikes,
                history: appConfig.history
            };      
            const updatedTodo = await client.graphql({
              query: updateTodo,
              variables: { input: todoDetails }
            });
           console.log('settings saved');       
         console.log(updatedTodo);
            snotify('Defaults Loaded'); 
            getSettingsAndHistDocAndLD();
         } catch (e) {
            console.log('save settings error: ', e);





            try {
              //Create new user
              console.log('creating new user record')
                const result = await client.graphql({
              query: createTodo,
              variables: {
                input: {
                 name: user.username ,
                 description: 'user doc' ,
                 settings: appConfig.settings,
                 likes: appConfig.likes,
                 dislikes: appConfig.dislikes,
                 cartLikes: appConfig.likes,
                 cartDislikes: appConfig.dislikes,
                 concLikes: appConfig.likes,
                 concDislikes: appConfig.dislikes,
                 eddLikes: appConfig.likes,
                 eddDislikes: appConfig.dislikes,
                 history: appConfig.history
                }
              }
            });
            console.log('user created');
          } catch (e) {
            console.log('Create new user Failed(Settings): ', e);
          };









        };
  };



  async function clearLD() {
    //cleat Likes/Dislikes 



    const userInput = prompt("Clear all likes and dislikes? Enter 'YES' to continue.","NO");
    // Handling user input
    if (userInput === 'YES') {
   


      try {
           
        //Get user doc
        const oneTodo = await client.graphql({
        query: todosByDescription,
        variables: { name: user.username }
        });
 //       console.log("L/D Clear User:" + user.username);    
 //       console.log(oneTodo);

        const todoDetails = {
            id: oneTodo.data.todosByDescription.items[0].id,
            likes: appConfig.likes,
            dislikes: appConfig.dislikes,
            cartLikes: appConfig.likes,
            cartDislikes: appConfig.dislikes,
            concLikes: appConfig.likes,
            concDislikes: appConfig.dislikes,
            eddLikes: appConfig.likes,
            eddDislikes: appConfig.dislikes,
        };      
        const updatedTodo = await client.graphql({
          query: updateTodo,
          variables: { input: todoDetails }
        });
//              console.log('L/D Cleared!');       
        //   console.log(updatedTodo);
           snotify('Likes/Dislikes have been cleared!'); 
           getSettingsAndHistDocAndLD();

     } catch (e) {
        console.log('L/D clear error: ', e);
        alert("Error: Likes/Dislikes not Cleared!");
    };



     
} else {
      //alert("Canceled");
};



            
   };



async function updateLikes(props) {
    if (props === "" ) { var tmpProps = 'none' } else { tmpProps = props}

    //apply New LikeList  
             try {
           
             //Get user doc
             const oneTodo = await client.graphql({
             query: todosByDescription,
             variables: { name: user.username }
             });
      //       console.log("updateLikesDoc User:" + user.username);    
      //       console.log(oneTodo);
 
             const todoDetails = {
                 id: oneTodo.data.todosByDescription.items[0].id,
                 likes: tmpProps
             };      
             const updatedTodo = await client.graphql({
               query: updateTodo,
               variables: { input: todoDetails }
             });
    //        console.log('likes saved');       
     //      console.log(updatedTodo);
            
          } catch (e) {
             console.log('save like error: ', e);
         };
   };

   async function updateDislikes(props) {
    if (props === "" ) { var tmpProps = 'none' } else { tmpProps = props}
    //apply New DisLikeList  
    try {
           
      //Get user doc
      const oneTodo = await client.graphql({
      query: todosByDescription,
      variables: { name: user.username }
      });
 //     console.log("updateDisLikesDoc User:" + user.username);    
//       console.log(oneTodo);

      const todoDetails = {
          id: oneTodo.data.todosByDescription.items[0].id,
          dislikes: tmpProps
      };      
      const updatedTodo = await client.graphql({
        query: updateTodo,
        variables: { input: todoDetails }
      });
    // console.log('dislikes saved');       
//    console.log(updatedTodo);
     
   } catch (e) {
      console.log('save dislike error: ', e);
  };
   };



  async function updateSettings() {
    //apply New Settings  
             try {
           
             //Get user doc
             const oneTodo = await client.graphql({
             query: todosByDescription,
             variables: { name: user.username }
             });
       //      console.log("updateSettingsDoc User:" + user.username);    
      //       console.log(oneTodo);
 
             const todoDetails = {
                 id: oneTodo.data.todosByDescription.items[0].id,
                 settings: JSON.stringify(settingsList)
             };      
             const updatedTodo = await client.graphql({
               query: updateTodo,
               variables: { input: todoDetails }
             });
      //      console.log('settings saved');       
      //     console.log(updatedTodo);
            snotify('Settings Saved'); 
            getSettingsAndHistDocAndLD();
          } catch (e) {
             console.log('save settings error: ', e);
         };
   };


   function seedDB() {
      //Create inital batch of strain in app dynamoDB 
        console.log('seedDB called')

        const userInput = prompt("Seed DB with 100 entries from Test_Strain_DB? Enter 'YES' to continue.","NO");
          // Handling user input
          if (userInput === 'YES') {
          //Do seed DB
    
          const sList = strain_db.data;
          //console.log(sList);
          var tmpList = sList.split('. ');

          let x = 0;
          for (let i = 0; i < 500; i++) {
          //  if (i === 279) {console.log(tmpList[i])};
            createRecord(tmpList[i]);
            x = i;
          };
          x = x + 1;
          alert("Success: DB Seeded " + x);
		} else {
			//alert("Canceled");
		};

        async function createRecord(rec){
      //    console.log(rec)
          var tmpList2 = rec.split(',')
          
          var tmpA = tmpList2[2].split(' - ')[1];
          var tmpB = tmpList2[3].split(' - ')[1];
          var tmpC = tmpList2[1].split('-')[0];
      //    console.log('creating record')
       //   console.log(tmpList2[0]);
     //     console.log(tmpC);
      //    console.log(tmpA);
     //     console.log(tmpB);
       //   console.log(tmpList2[4]);
     //     console.log(tmpList2[5]);

                    //add new strain  
                    
                      try {
                     // console.log('creating new strain record')
                     if (tmpC === "indica") {var tmpColor = appConfig["color-indica"]} else if (tmpC === "sativa" ) { tmpColor = appConfig["color-sativa"]  } else { tmpColor = appConfig["color-hybrid"]};
                      const result = await client.graphql({
                        query: createTodo,
                       variables: {
                         input: {
                           name: 'flower' ,
                          description: tmpList2[0] ,
                           type: tmpC ,
                          effect: tmpList2[4],
                          flavor: tmpList2[5],
                          thc: tmpA ,
                          cbd: tmpB ,
                          location: "Test Store",
                          color: tmpColor,
                         }
                       }
                      });
             //        console.log('flower strain created');
                   } catch (e) {
                      console.log('Create new strain Failed: ', e);
                    };

        };


   };
   

   async function clearDB() {
    console.log('Clear DB Called');


		const userInput = prompt("Clear all strains from DB? Enter 'YES' to continue.","NO");

		// Handling user input
		if (userInput === 'YES') {
			//Do clear DB
      
    try {
    
      const todos = await client.graphql({
        query: todosByDescription,
        variables: { name: 'flower' , limit: 2000 }
      });

     const todoList = todos.data.todosByDescription.items;
     // console.log(todoList);
      console.log(todoList.length);
      var x = Number(todoList.length) - 1;

      for (let i = 0; i <= x; i++) {
       // console.log(todoList);
        deleteRecord(todoList[i].id);
      };
      alert("Success: DB Cleared");

    } catch (e) {
      console.log('getStrains failed: ', e);
  
    };


		} else {
			alert("Canceled");
		};


    
    async function deleteRecord(rec){
       // console.log('Deleting ' + rec)


        try {
            const todoDetails = {
            id: rec       
          };
          const deletedTodo = await client.graphql({
            query: deleteTodo,
            variables: { input: todoDetails }
          });
         
        } catch (error) {
            console.log('error delete bug 201' , error);
        };

    };

   };
   

async function addFeedback() {



     try {
      //Get user doc
      const oneTodo = await client.graphql({
      query: todosByDescription,
      variables: { name: user.username }
        });
    //    console.log("SettingsDoc4 User:" + user.username);    
    //console.log(oneTodo);
      var tmpHist= oneTodo.data.todosByDescription.items[0].history;
        setHist(tmpHist);
   //console.log('Loaded User Doc4')


//create new bug record
try {
  const result = await client.graphql({
     query: createTodo,
    variables: {
       input: {
        name: 'feedback',
        description: email,
        history: comment 
       }
     }
   });
   alert('Your feedback has been submitted. Thank you!')
   //reset input form
   setComment('')
   //console.log(result);
   } catch (e) {
     console.log('Create Feedback Record Failed: ', e);
   };



      } catch (e) {
      console.log('Get User Settings Doc4 Failed(FB): ', e);
      };





};


function blank() {
  
};

function goBack() {
  navigate(`/`);
};


if (email === "rkz@toughguys.biz") {var tmpHide=false} else {tmpHide=true};


  return (
    <>


<div className="Headder"       
        >
          <span style={{ }}>
        {ver}
        </span>

 

        <span style={{paddingLeft:'1vw'}} >

        <span style={{ paddingRight:"4px",fontSize:"10px"}} >
 
        </span>
        
        
        


        <button className="HeadderButton" onClick={goBack}>Back</button>
        
        </span>


        </div>
   
    



<div className = "main2">

<div className = "title">
SETTINGS
</div>

<div className = "body-settings">

<Flex
  direction='column'
  //AlignItems='flex-start'
  //AlignContent='flex-start'
  //justifyContent='flex-start'
  textAlign='left'
>
  <span>
  Account: {email.toUpperCase()}
  </span>
  <span>
  Data Source: COMMUNITY_STRAIN_DB <Button display="none" backgroundColor ="white" fontSize='10px' marginLeft='.35rem' height='20px' onClick={handleClickToOpen5}>Edit</Button>
  </span>
  
  <span hidden>
  AI Model: GPT4o <Button display="none"  backgroundColor ="white" fontSize='10px' marginLeft='.35rem' height='20px' onClick={handleClickToOpen4}>Edit</Button>
  </span>
  <span>
  AI Personality: {settingsList.personality} <Button display="none" visibility="hidden" backgroundColor ="white" fontSize='10px' marginLeft='.35rem' height='20px' onClick={handleClickToOpen3}>Edit</Button>
  </span>
  <span>
  Max List Items: {settingsList.maxlist} <Button backgroundColor ="white" fontSize='10px' marginLeft='.35rem' height='20px' onClick={handleClickToOpen6}>Edit</Button>
  </span>
  <span hidden>
  Allow Jokes: {settingsList.jokes}
  </span>
  <span hidden>
  Allow General Conversation: {settingsList.general}
  </span>

  <span hidden>
Personal Likes / Dislikes 
<Button backgroundColor ="white" fontSize='10px' marginLeft='.35rem' height='20px' onClick={handleClickToOpen7}>Show</Button>
</span>

<span hidden>
Last Conversation: 
<Button backgroundColor ="white" fontSize='10px' marginLeft='.35rem' height='20px' onClick={handleClickToOpen1}>Show</Button>
</span>


<span hidden = {tmpHide} style={{ backgroundColor:"grey", boxShadow:'0 0 1px 1px grey'}}>
Admin View Bug Reports 
<Button backgroundColor ="white" fontSize='10px' marginLeft='.35rem' height='20px' onClick={handleClickToOpen2}>Show</Button>
</span>

<span hidden = {tmpHide} style={{backgroundColor:"grey", boxShadow:'0 0 1px 1px grey'}}>
Admin View Feedback 
<Button backgroundColor ="white" fontSize='10px' marginLeft='.35rem' height='20px' onClick={handleClickToOpen9}>Show</Button>
</span>

</Flex>

</div>


<span style={{paddingTop:'30px'}}>
  
  <Button backgroundColor ="#4e4e4e" color="white" fontSize='11px' paddingLeft='10px' paddingRight='10px' minWidth='150px' onClick={defaultSettings}>Apply Defaults</Button>
  
  
  <br></br><br></br>
  <span>
  <Button backgroundColor ="#4e4e4e" color="white" fontSize='11px' paddingLeft='10px' paddingRight='10px' minWidth='150px' onClick={clearLD}>Clear Likes/Dislikes</Button>
  <br></br><br></br>
  </span>
  <span hidden = {tmpHide} style={{backgroundColor:'grey'}} >
  <Button backgroundColor ="darkorange" color="white" fontSize='11px' paddingLeft='10px' paddingRight='10px' minWidth='150px' height="10px" onClick={seedDB}>Seed Stain DB</Button>
  <br></br>
  </span>
  <span hidden = {tmpHide} style={{backgroundColor:'grey'}}  >
  <Button backgroundColor ="darkorange" color="white" fontSize='11px' paddingLeft='10px' paddingRight='10px' minWidth='150px' height='10px' onClick={clearDB}>Clear Strain DB</Button>
  <br></br><br></br>
  </span>
<Button backgroundColor ="#5f1c1c" color="white" fontSize='11px' paddingLeft='10px' paddingRight='10px' minWidth='150px' onClick={signOut}>Log Out</Button>
</span>    

  </div>





  <div style={{}}> 
      <Dialog open={open1} onClose={handleToClose1}>
        <DialogTitle>
          Last Conversation
        </DialogTitle>
        <DialogContent >
          <DialogContentText>
          {hist}
          </DialogContentText> 
        </DialogContent>
        <DialogActions> 
          <ButtonMui onClick={handleToClose1}>
            Exit
          </ButtonMui> 
        </DialogActions>      
      </Dialog>
    </div>



    <div style={{}}> 
      <Dialog open={open2} onClose={handleToClose2}>
        <DialogTitle>
          All Bug Reports
        </DialogTitle>
        <DialogContent >
          <DialogContentText>
            
            {bugList.map((bug) => (
              <li className='' style={{	}} key={`bug${bug.id}`} >
                <span>{bug.id}</span><span>{bug.createdAt}</span> <br></br> <span>{bug.history}</span> 
                <RemoveButtonBug
                                    bugId={bug.id}
                                    funct={handleClickToOpen2} 
                                />

                <br></br><br></br>
              </li>
            ))};

          </DialogContentText> 
        </DialogContent>
        <DialogActions> 
          <ButtonMui onClick={handleToClose2}>
            Exit
          </ButtonMui> 
        </DialogActions>      
      </Dialog>
    </div>




    <div style={{}}> 
      <Dialog open={open3} onClose={handleToClose3}>
        <DialogTitle>
          Custom Bud-Tender Personality
        </DialogTitle>
        <DialogContent >
          <DialogContentText>

                 

    <label style={{paddingRight:'1px'}}>Who am I? (In one sentance)</label><br></br>
    <input value={comment1} onChange={(e) => setComment1(e.target.value)} type='text' id='comment1' name='comment1' className='inp-field'  style={{width:'300px'}}/><br></br>

          </DialogContentText> 
        </DialogContent>
        <DialogActions> 
          <ButtonMui onClick={handleToClose3}>
            Exit
          </ButtonMui> 
        </DialogActions>      
      </Dialog>
    </div>



    <div style={{}}> 
      <Dialog open={open4} onClose={handleToClose4}>
        <DialogTitle>
          Select Current GPT Model 
        </DialogTitle>
        <DialogContent >
          <DialogContentText>
            ...Under Construction...
          </DialogContentText> 
        </DialogContent>
        <DialogActions> 
          <ButtonMui onClick={handleToClose4}>
            Exit
          </ButtonMui> 
        </DialogActions>      
      </Dialog>
    </div>


    <div style={{}}> 
      <Dialog open={open5} onClose={handleToClose5}>
        <DialogTitle>
          Select Current Data Source
        </DialogTitle>
        <DialogContent >
          <DialogContentText>
            ...Under Construction...
          </DialogContentText> 
        </DialogContent>
        <DialogActions> 
          <ButtonMui onClick={handleToClose5}>
            Exit
          </ButtonMui> 
        </DialogActions>      
      </Dialog>
    </div>

    <div style={{}}> 
      <Dialog open={open6} onClose={handleToClose6}>
        <DialogTitle style={{backgroundColor:'#242424', color:'#d8d8d8', borderBottomStyle:'none', borderTopStyle:'solid',borderLeftStyle:'solid',borderRightStyle:'solid', borderWidth:'2px', borderColor:'#4c663b'}}>
          Select Max Items Returned
        </DialogTitle>
        <DialogContent style={{backgroundColor:'#242424', color:'#d8d8d8', borderBottomStyle:'none', borderTopStyle:'none',borderLeftStyle:'solid',borderRightStyle:'solid', borderWidth:'2px', borderColor:'#4c663b'}} >
          <DialogContentText style={{backgroundColor:'#242424', color:'#d8d8d8'}}>
            
            
    <label style={{paddingRight:'1px'}}>Max Items (1-10)</label><br></br>
    

    <input
        value={comment6}
        onChange={(e) => setComment6(e.target.value)}
        type='text'
        name='comment6'
        id='comment6'
        className='inp-field'
        style={{width:'70px'}}
    />

          </DialogContentText> 
        </DialogContent>
        <DialogActions style={{backgroundColor:'#242424', color:'#d8d8d8', borderBottomStyle:'solid', borderTopStyle:'none',borderLeftStyle:'solid',borderRightStyle:'solid', borderWidth:'2px', borderColor:'#4c663b'}}>
        <Button style={{backgroundColor:'', color:"#80b35e" , fontWeight:'normal'}}  onClick={handleSubmit6}>
            Save
          </Button> 
          <Button style={{backgroundColor:'', color:"#a0a0a0" , fontWeight:'normal'}}  onClick={handleToClose6}>
            Cancel
          </Button>  
        </DialogActions>      
      </Dialog>
    </div>




    <div stlye={{}}> 
      <Dialog open={open7} onClose={handleToClose7}>
        <DialogTitle style={{backgroundColor:'#242424', color:'#d8d8d8', borderBottomStyle:'none', borderTopStyle:'solid',borderLeftStyle:'solid',borderRightStyle:'solid', borderWidth:'2px', borderColor:'#4c663b'}}>
          Showing Personal Likes / Dislikes
        </DialogTitle>
        <DialogContent style={{backgroundColor:'#242424', color:'#d8d8d8', borderBottomStyle:'none', borderTopStyle:'none',borderLeftStyle:'solid',borderRightStyle:'solid', borderWidth:'2px', borderColor:'#4c663b'}}>
          <DialogContentText style={{backgroundColor:'#242424', color:'#d8d8d8'}}>
            

          LIKES:
          {splLikeList.map((bug) => (
              <li className='' style={{	}} key={`bug${bug}`} >
                <span>{bug}</span>
                <RemoveButtonLD
                                    name={bug}
                                    functList={handleLikesChange} 
                                />

                <br></br><br></br>
              </li>
            ))}
            <br></br><br></br>
            DISLIKES:
            {splDislikeList.map((bug) => (
              <li className='' style={{	}} key={`bug${bug}`} >
                <span>{bug}</span>
                <RemoveButtonLD
                                    name={bug}
                                    functList={handleDislikesChange} 
                                />

                <br></br><br></br>
              </li>
            ))}

        </DialogContentText> 
        </DialogContent>
        <DialogActions style={{backgroundColor:'#242424', color:'#d8d8d8', borderBottomStyle:'solid', borderTopStyle:'none',borderLeftStyle:'solid',borderRightStyle:'solid', borderWidth:'2px', borderColor:'#4c663b'}}> 
          <Button style={{backgroundColor:'', color:"#a0a0a0" , fontWeight:'normal'}}  onClick={handleToClose7}>
            Exit
          </Button> 
        </DialogActions>      
      </Dialog>
    



    <Dialog open={open8} onClose={handleToClose8}>
        <DialogTitle style={{backgroundColor:'#242424', color:'#d8d8d8', borderBottomStyle:'none', borderTopStyle:'solid',borderLeftStyle:'solid',borderRightStyle:'solid', borderWidth:'2px', borderColor:'#4c663b'}}>
          
             Send Feedback

        </DialogTitle>
        <DialogContent style={{backgroundColor:'#242424', color:'#d8d8d8', borderBottomStyle:'solid', borderTopStyle:'none',borderLeftStyle:'solid',borderRightStyle:'solid', borderWidth:'2px', borderColor:'#4c663b'}}>
          <DialogContentText>
            
          <Flex 
          direction="column"
          textAlign="center"
          alignItems="center"
 
          minWidth="200px"
            
          minHeight="55vh"
          gap="0.5vw"
          paddingTop="1vw"
          fontSize="14px"
          >
            
			<form  onSubmit={handleSubmit8}>
    
        <textarea
            
            rows={17}
			cols={40}
           
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            type='text'
            name='comment'
            
        />

             <Flex 
          direction="column"
          gap = "10px"
          paddingTop='15px'
          >
              <Button  style={{backgroundColor:'', color:"#80b35e" , fontWeight:'normal'}} onClick={handleSubmit8}  >Send</Button>
              <Button  style={{backgroundColor:'', color:"#a0a0a0" , fontWeight:'normal'}} onClick={handleToClose8}  >Cancel</Button>
              </Flex>
		</form>

          
            
            </Flex>


          </DialogContentText> 
        </DialogContent>
        
      </Dialog>
    





    <Dialog open={open9} onClose={handleToClose9}>
        <DialogTitle>
          
        View Feedback

        </DialogTitle>
        <DialogContent >
          <DialogContentText>
          

          
          {bugList.map((bug) => (
              <li className='' style={{	}} key={`bug${bug.id}`} >
               <span> {bug.createdAt} {bug.description} {bug.history}</span> 
                <RemoveButtonFB
                                    bugId={bug.id}
                                    funct={handleClickToOpen9} 
                                />

                <br></br><br></br>
              </li>
            ))};

          </DialogContentText> 
        </DialogContent>
        <DialogActions> 
          <ButtonMui onClick={handleToClose9}>
            Exit
          </ButtonMui> 
        </DialogActions>      
      </Dialog>




    </div>






    </>
);

};

export default withAuthenticator(Settings);
