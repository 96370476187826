
//import React, { Fragment } from 'react';
import React, { useState } from "react";
import { post } from 'aws-amplify/api';
//import { withAuthenticator  } from '@aws-amplify/ui-react';
import { generateClient } from 'aws-amplify/api';
import { createTodo, updateTodo, deleteTodo } from '../graphql/mutations';
import { listTodos, getTodo, todosByDescription } from '../graphql/queries';
import { Amplify } from 'aws-amplify';
import awsExports from '../aws-exports';
import { getCurrentUser } from 'aws-amplify/auth';
import strain_db_1280 from '../strain_db_1280';
Amplify.configure(awsExports);

const client = generateClient();









class ActionProvider {





    constructor(createChatBotMessage, setStateFunc) {
      this.createChatBotMessage = createChatBotMessage;
      this.setState = setStateFunc;
    }
    





 
////////////////////////////////////////
    async greet(content,tmpHist) {
      
     //const username = "test";
     const { username, userId} = await getCurrentUser();

       async function updateHist(convo) {
        try {
            //Get user doc
            console.log("Cono:" + convo)
              const oneTodo = await client.graphql({
            query: todosByDescription,
            variables: { name: username }
              });
              console.log("updateHIST User:" + username);    
          console.log(oneTodo);

          var tmpN2 = JSON.stringify(tmpHist);

          const todoDetails = {
            id: oneTodo.data.todosByDescription.items[0].id,
            history: tmpN2 + ";" + convo
          };      
          const updatedTodo = await client.graphql({
            query: updateTodo,
            variables: { input: todoDetails }
          });
          console.log('User Hist Updated!!');
    
        


            } catch (e) {
            console.log('updateHist(1) failed: ', e);
          }


      };
      





  //Load Strain DB from Master Todo
  //    const oneTodo = await client.graphql({
  //      query: getTodo,
  //      variables: { id: '890329fc-6967-4b43-9e5c-84f03c6d5ee8' }
  //    });
  //    var tmpN = JSON.stringify(oneTodo.data.getTodo.name);




  //Get user doc
   const oneTodo = await client.graphql({
    query: todosByDescription,
    variables: { name: username }
    });
    console.log("get current settings User:" + username);    
   // console.log(oneTodo);
    //Get Max Items from settings doc
    var tmpList= oneTodo.data.todosByDescription.items[0].settings;
    var tmpLikes= oneTodo.data.todosByDescription.items[0].likes;
    var tmpDislikes= oneTodo.data.todosByDescription.items[0].dislikes;
    //console.log(tmpList);
    var tmpList2 = JSON.parse(tmpList);
    //console.log(tmpList2);
    //console.log(tmpList2.maxlist);

      
      var tmpMaxList = tmpList2.maxlist;
     // console.log( 'MaxList' + tmpMaxList);
      //Start Chat Pre-Load Data
      const tmpN1 = () => 'I am normaly asking about a marijuana strain and its effects. Match full strain names exactly. Only reply with strains from the offical strain list, unless I am asking a general question.  If I ask about a strain that is not on the offical list then tell me you do not have it in your database and that we will look into it.  Do not respond with the full strain list or all the strains you know, it will take too long.  The offical strain list uses the format of strain name, strain type, THC content, CBD content, main effect, and flavor.  Answer from the data using proper sentance format. Offical Strain List: '
    //  console.log(tmpN1);
    //add Strain DB info to Pre-Load Data!!!!
    //const StrainDB = strain_db_1280.data;
    var StrainDB = strain_db_1280.data;


//console.log('loading strains')
    try {
    
      const todos = await client.graphql({
        query: todosByDescription,
        variables: { name: 'flower' }
      });


     //console.log(todos);
     const todoList = todos.data.todosByDescription.items;
   
     // console.log(todoList);

     //Overwrite local test strain db with live data
      StrainDB = todoList.map((strain) => ( strain.description + "," + strain.type + "," + strain.thc + "," + strain.cbd + "," + strain.flavor + "," + strain.effect + "." )).join(' ');


     // console.log(StrainDB)

    } catch (e) {
      console.log('getStrains2-WAP failed: ', e);
  
    };
  



    const tmpN = tmpN1 + ' ' +  StrainDB + '.  I prefer these strains: ' + tmpLikes  + '. I hate the following strains: ' +  tmpDislikes +  '.  Keep lists down to a maximum of ' + tmpMaxList + ' items. ' ;

    //  `
   //   Offical Strain List:
   //   Wedding Cake,indica-dominant-hybrid,22.67 - 25.33%,0.4 - 0.68%,Relaxed,Sweet. Ice Cream Cake,indica-dominant-hybrid,20.78 - 23.02%,0.26 - 1.34%,Relaxed,Pungent. Gelato,indica-dominant-hybrid,18.83 - 21.17%,0.36 - 3.88%,Relaxed,Berry. Apple Fritter,hybrid,27.5 - 30.5%,0.38 - 0.65%,Happy,Apple. Gushers,hybrid,19 - 22%,0.3 - 0.62%,Relaxed,Sweet. Mimosa,sativa-dominant-hybrid,22.75 - 25%,0.2 - 0.52%,Happy,Citrus. Northern Lights,indica,18.8 - 21.4%,0.24 - 0.5%,Relaxed,Earthy. Cherry Pie,indica-dominant-hybrid,18.78 - 21.78%,0.33 - 4.61%,Relaxed,Sweet. London Pound Cake,indica-dominant-hybrid,28 - 30%,0.18 - 0.38%,Relaxed,Lemon. Wedding Crasher,hybrid,20.75 - 22.75%,0.27 - 0.59%,Relaxed,Diesel. Fruity Pebbles,hybrid,24 - 28%,0 - 0.38%,Sleepy,Woody. Zookies,hybrid,22.25 - 24.5%,0.25 - 0.56%,Relaxed,Flowery. Sunset Sherbet,indica-dominant-hybrid,17 - 20%,0.48 - 0.79%,Sleepy,Citrus.
   //   `;
  

 


    //Add Prev conversation HIST to Pre-Load Data --SKIPPED!!!!
      var tmpN2 = JSON.stringify(tmpHist)
     // console.log("HIST: " + tmpN);






   
        //Build chatbot request with current content, strain db data and prevoius message history
        var raw = JSON.stringify({"act": "cbot","data":content, "db": tmpN + ". Also here are our previous messages so far:" + tmpN2 });

    try {


      const restOperation = post({
        apiName: 'waidAPI',
        path: '/items',
        options: {
          body: raw,
          headers: {'Content-Type': 'application/json'}, 
           redirect: 'follow', 
        }
      });
      const { body } = await restOperation.response;
     const response = await body.text();  
      console.log('AP1 POST call succeeded');
     // console.log("RSP:" + response);
     
      var obj = JSON.parse(response);
      var tmp1 = JSON.stringify(obj.error);
//console.log("tmp1:" + tmp1);


if (tmp1 === undefined) {  
  //console.log("tmp1: undef" + tmp1); 

      tmp1 = JSON.stringify(obj.choices);
      var tmp2 = tmp1.replace(/\[/g,'');
      var tmp3 = tmp2.replace(/\]/g,'');
      //console.log("tmp3:" + tmp3);
      var tmp4 = JSON.parse(tmp3);
      var tmp5 = tmp4.message.content;
      var tmp6 = JSON.stringify(tmp5);
      //console.log("tmp6:" + tmp6);
      var tmp7 = tmp6.replace(/\\"/g,'');
    //  var tmp8 = tmp7.replace(/\\n/g,'  ');
      var tmp8 = tmp7.replace(/\\n/g,"... ");



    } else {

      var obj2 = JSON.parse(tmp1);
      tmp2 = JSON.stringify(obj2.message);
      tmp3 = JSON.stringify(obj2.code);
      console.log("tmp3:" + tmp3);
      if (tmp3 === '"rate_limit_exceeded"' ){
        tmp8 = "I'm sorry, give me a few seconds.  We have exceeded my answer rate limits."; 
      } else { 
        tmp8 = "I have had a general error: " + tmp2; 
      };
    };







      const greetingMessage = this.createChatBotMessage(tmp8);
      this.updateChatbotState(greetingMessage);
  

//update conversaion hist on user doc
updateHist("Last User Message: " + content + ' ; Last Bot Reply: ' + tmp8);





    } catch (e) {
      console.log('AP1 POST call failed: ', e);

      
    const greetingMessage = this.createChatBotMessage("I must apologize. I have had an error!\n" + e.message);
    this.updateChatbotState(greetingMessage);
    };


  







  };

 ///////////////////////







  

   // updateChatbotState(message) {
   updateChatbotState(message) {
   
  // NOTE: This function is set in the constructor, and is passed in      // from the top level Chatbot component. The setState function here     // actually manipulates the top level state of the Chatbot, so it's     // important that we make sure that we preserve the previous state.
   
      
     this.setState(prevState => ({
          ...prevState, messages: [...prevState.messages, message]
      }))
    }
    



    
  }
  

  
  



  export default ActionProvider
  

  