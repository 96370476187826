import React, { useEffect, useMemo, useState } from "react";
import 'react-chatbot-kit/build/main.css';
import '@aws-amplify/ui-react/styles.css';
import './App.css';
import { Amplify } from 'aws-amplify';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { withAuthenticator } from "@aws-amplify/ui-react";
import appConfig from './AppConfig';
//import {
//  Flex,
//  Heading,
//  Image,
//Button,
//  View,
//} from "@aws-amplify/ui-react";
import awsExports from './aws-exports';
import Home from "./components/Home";
import Settings from "./components/Settings";
import Products from "./components/Products";
import Favorites from "./components/Favorites";
import Notfound from './components/Notfound';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import "./styles.css";
import './toastify.css';
Amplify.configure(awsExports);





function App({signOut,user}) {
  


     
     const ver = appConfig.ver;
     
     
       useEffect(() => {
        document.title = 'WAID ALPHA';
        
         }, []);
       
     
        
     
       return (
         <div className="App">
     
     


     <BrowserRouter>
                <Routes>
                     <Route path='/' element={<Home />} />
                     <Route path='/settings' element={<Settings />} />
                     <Route path='/products' element={<Products />} />
                     <Route path='/favorites' element={<Favorites />} />
                     <Route path="*" element={< Notfound />}></Route>
                </Routes>
            </BrowserRouter>
          
            

            <ToastContainer
      //position="top-right"
      //progressClassName="toastProgress"
      //bodyClassName="toastBody"
      position="top-right"
      autoClose={1500}
      hideProgressBar={false}
      newestOnTop={true}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable={false}
      pauseOnHover={false}
      //theme="dark"
      //theme="light"
      theme="colored"
      icon={true}
      >
      </ToastContainer>

         </div>
     
     
     
       );
     }
     


//export default App;
export default withAuthenticator(App)
