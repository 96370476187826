//v1.7
const appConfig = {
    "name": "WAID",  
    "ver": "0.3.5e",  
    "settings":'{"maxlist":"10","personality":"DEFAULT","jokes":"YES","general":"YES"}',
    "likes":"none",
    "dislikes":"none",
     "history":"none",
     "location":"Test Store",
     "color-indica":"#80008086",
     "color-sativa":"#ff44008a",
     "color-hybrid":"#008000a2",
};

export default appConfig;
