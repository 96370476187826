import React, { useState} from "react";

import { deleteTodo} from '../graphql/mutations';
import { generateClient } from 'aws-amplify/api';

function RemoveButtonLD( props)  {


    const client = generateClient();


    const [state, setState] = useState("--");
    const [list, setList] = useState("--");


    function listChange(f) {
      setList(f);
      props.functList(f);
  //   to set list change
  };
  

    function stateChange(f) {
        setState(f);
        props.funct(f);
    //   to set loader
  };




    async function handleRemoveLD() {
              //call listChange witrh new list
              console.log('RemoveLDButton Pressed')
      listChange(props.name);
          
    };

//console.log(props)

if (props.name === 'none') {var tmpHidden = true} else {tmpHidden = false};


    return (
            <>{tmpHidden}
            <button hidden={tmpHidden}  style={{borderWidth:'0px',backgroundColor:'red', color:'white', paddingLeft:'5px' , paddingRight:'5px', marginLeft:'10px'}} onClick={handleRemoveLD} >X</button> 
            </>
    );
};

export default RemoveButtonLD;