import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ComponentPropsToStylePropsMap, withAuthenticator  } from '@aws-amplify/ui-react';
//import Chatbot from 'react-chatbot-kit';
//import ActionProvider from './WAIDActionProvider';
//import MessageParser from './WAIDMessageParser';
//import { createChatBotMessage } from "react-chatbot-kit";
import {
  Flex,
//  Heading,
//  Image,
Button,
  View,
} from "@aws-amplify/ui-react";
import { fetchAuthSession } from 'aws-amplify/auth';
import { generateClient } from 'aws-amplify/api';
import { createTodo, updateTodo, deleteTodo} from '../graphql/mutations';
import { listTodos, getTodo, todosByDescription } from '../graphql/queries';

import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import ButtonMui from "@mui/material/Button";
import RemoveButtonStrain from "./RemoveButtonStrain";
import LikeButtonStrain from "./LikeButtonStrain";
import DislikeButtonStrain from "./DislikeButtonStrain";
import MenuButtonStrain from "./MenuButtonStrain";
import EditButtonStrain from "./EditButtonStrain";
import Comments from "./Comments";
import { getCurrentUser } from 'aws-amplify/auth';
import { toast } from "react-toastify";

import logo from '../logo.svg';
import appConfig from '../AppConfig';




function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    let id = setInterval(() => {
      savedCallback.current();
    }, delay);
    return () => clearInterval(id);
  }, [delay]);
};


function Products({signOut,user}) {


 // function snotify(note) {toast.success(note);};
  function inotify(note) {toast.info(note)};
  //function enotify(note) {toast.error(note)};
  function wnotify(note) {toast.warning(note)};

  //AutoUpdate from backend
 var tmpcnt = 10000;
 useInterval(() => {
   getStrainsAndUserLD();
   //console.log('fetch')
 }, tmpcnt);

    function snotify(note) {toast.success(note);};
    //function inotify(note) {toast.info(note)};
    //function enotify(note) {toast.error(note)};
    function wnotify(note) {toast.warning(note)};
  const ver = appConfig.name;
  
  const [state1, setState1] = useState("");
  const [scount, setScount] = useState("");
  const [sel1, setSel1] = useState("");
  const [sel2, setSel2] = useState("");
  const [sel3, setSel3] = useState("");
  const [loading, setLoading] = useState("1");

  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [hist, setHist] = useState("");

  const [strainList, setStrainList] = useState([]);
  const [likeList, setLikeList] = useState([]);
  const [dislikeList, setDislikeList] = useState([]);
  const [nameList, setNameList] = useState([]);

  const [menuStrainId, setMenuStrainId] = useState("");
  const [menuStrainName, setMenuStrainName] = useState("");
  const [menuStrainType, setMenuStrainType] = useState("");
  const [comment, setComment] = useState("");
  const [open1, setOpen1] = useState(false);


  const [comment1, setComment1] = useState("");
  const [comment2, setComment2] = useState("");
  const [comment3, setComment3] = useState("");
  const [comment4, setComment4] = useState("");
  const [comment5, setComment5] = useState("");
  const [comment6, setComment6] = useState("");
  const [currentType, setCurrentType] = useState("flower");
  const [newType, setNewType] = useState("flower");
  const [uname, setUname] = useState("");
  
    const client = generateClient();
  
  
    useEffect(() => {
      //document.title = ver;
     // getUser();
      getStrainsAndUserLD(currentType);
    
      }, []);
    


  const handleToClose1 = () => {
      setOpen1(false);  
  };
  const handleClickToOpen1 = () => {
    setOpen1(true);
  };

  const [open1e, setOpen1e] = useState(false);
  const handleToClose1e = () => {
      setOpen1e(false);  
  };
  const handleClickToOpen1e = () => {
    handleToClose3(false);
    setComment2(menuStrainType);
    //set to default values
    setComment5('calming');
    setComment6('apple')
if (menuStrainType==='indica') {
  setSel1('');
  setSel2('selected');
  setSel3('');
} else if (menuStrainType==='sativa') {
  setSel1('');
  setSel2('');
  setSel3('selected');
} else {
  setSel1('selected');
  setSel2('');
  setSel3('');  
};


    setOpen1e(true);
  };


  const [open2, setOpen2] = useState(false);
  const handleToClose2 = () => {
      setOpen2(false);  
  };
  const handleClickToOpen2 = () => {
      setOpen2(true);
  };

  const [open3, setOpen3] = useState(false);
  const handleToClose3 = () => {
      setOpen3(false);  
  };
  const handleClickToOpen3 = (strainId,strainName,strainType) => {
    setMenuStrainId(strainId);
    setMenuStrainName(strainName);
    setMenuStrainType(strainType);
    setOpen3(true);
  };

  const [open4, setOpen4] = useState(false);
  const handleToClose4 = () => {
      setOpen4(false);  
  };
  const handleClickToOpen4 = () => {
    setOpen4(true);
  };
  const handleSubmit4 = (e) => {
    e.preventDefault();
    //👇🏻 calls the function
      updateEffect();
      handleToClose4();
  };
  


  const [open5, setOpen5] = useState(false);
  const handleToClose5 = () => {
      setOpen5(false);  
  };
  const handleClickToOpen5 = () => {
    setOpen5(true);
  };
  const handleSubmit5 = (e) => {
    e.preventDefault();
    //👇🏻 calls the function
      updateNote();
      handleToClose5();
  };
  
	
  const handleSubmit1 = (e) => {
    e.preventDefault();
    //👇🏻 calls the function
      addStrain();
      handleToClose1();
};

const handleChangeCurrentType = (e) => {
  //👇🏻 calls the function
    setCurrentType(e);
    setLoading('1');
    getStrainsAndUserLD(e);
};

const handleChangeNewType = (e) => {
  //👇🏻 calls the function
    setNewType(e);
};



	
const handleSubmit1e= (e) => {
  e.preventDefault();
  //👇🏻 calls the function
    editStrain();
    handleToClose1e();
};


async function editStrain() {
  console.log('edit strain')


//Input Validation


    
    //console.log("nan" + isNaN(comment3));
    if ( isNaN(comment3) || isNaN(comment4) || comment3 === '' || comment4 === '' || comment3 > 100 || comment4 > 100 || comment3 < 0 || comment4 < 0 )  {  
        //THC/CBD is not a number, blank or out f range
          wnotify('Error: TCH/CBD must be a number from 1 to 100');  
      } else {
    
          //Verify Effect
        if (comment5 ==='') {
          //Effect is blank
          wnotify('Error: Effect can not be blank'); 
        } else {
    
    
          //Verify Flavor
          if (comment6 ==='') {
            //Flavor is blank
            wnotify('Error: Flavor can not be blank'); 
          } else {
    
    
                //update strain data
                setLoading('1');
                
                try {
               // console.log('creating new strain record')
                  if (comment2 === "indica") {var tmpColor = appConfig["color-indica"]} else if (comment2 === "sativa" ) { tmpColor = appConfig["color-sativa"]  } else { tmpColor = appConfig["color-hybrid"]};
    
                const result = await client.graphql({
                  query: updateTodo,
                 variables: {
                   input: {
                    id: menuStrainId,
                    type: comment2 ,
                    effect: comment5 ,
                    flavor: comment6,
                    thc: comment3 +'%' ,
                    cbd: comment4 +'%' ,
                    color: tmpColor,
                    location: 'Test Store', 
                   }
                 }
                });
               // console.log('flower strain updated');
               // alert('Strain Added!');
               snotify('Strain Updated'); 
               //Reset Form
                setComment1('');
                setComment2('');
                setComment3('');
                setComment4('');
                setComment5('');
                 setComment6('');
             } catch (e) {
                console.log('update strain Failed: ', e);
               alert('Error Updating Strain!');
              };
    
    
    
    
    
    
    
    
    
                };
              };
            };
        
};
  
  
      async function handleRemoveStrain() {
        handleToClose3();
  

        const userInput = prompt("Permanently delete this strain from the DB? Enter 'YES' to continue.","NO");
        // Handling user input
        if (userInput === 'YES') {
   



  
          try {
       
            setLoading('1');
       const todoDetails = {
          id: menuStrainId       
        };
  
        const deletedTodo = await client.graphql({
          query: deleteTodo,
          variables: { input: todoDetails }
        });
  
  
            //alert('Strain Deleted!');
            snotify('Strain Deleted'); 
          
            setMenuStrainName("");
            setMenuStrainId("");
            setMenuStrainType("");
            
           
          } catch (error) {
              console.log('error delete bug 103' , error);
            };



      
          
  } else {
     //   alert("Canceled");
  };
  
  
  
  
            
      };



      async function handlePnote() {
        handleToClose3();
        handleClickToOpen5(true);

      };


      async function updateNote() {
        alert('disabled (554)')


      };


      async function handlePeff() {
        handleToClose3();
        handleClickToOpen4(true);


      };

      async function updateEffect() {
        alert('disabled (553)')


      };


const handleLikeListChange = (e) => {
  //console.log('Like List change called');
 // console.log('e:' + e);
  const oldList = String(likeList);
 const newList = oldList.split(':');
 const oldDLList = String(dislikeList);
 const newDLList = oldDLList.split(':');
//console.log('newList' + newList);
if (newList.includes(menuStrainName)) {
 // console.log('dup');
  wnotify('Error: Duplicate like');
  setMenuStrainName("");
  setMenuStrainId("");
  handleToClose3();
} else {
  if (newDLList.includes(menuStrainName)) {
    //Already in DisLikes
    wnotify('Error: Strain is already on dislikes list');
    setMenuStrainName("");
    setMenuStrainId("");
    handleToClose3();
  } else {
  //Update and Save User Doc
  if (oldList === "none") {
    updateLikes( menuStrainName );
    setLikeList(menuStrainName);
  } else {
    updateLikes( oldList + ":" + menuStrainName  );
    setLikeList( oldList + ":" + menuStrainName )
  };
  snotify('Strain added to likes list');
  setMenuStrainName("");
    setMenuStrainId("");
    handleToClose3();
  };
};
};


const handleDislikeListChange = (e) => {
  //console.log('DisLike List change called');
  //console.log('e:' + e);
  const oldLList = String(likeList);
  const newLList = oldLList.split(':');
  const oldDLList = String(dislikeList);
 const newDLList = oldDLList.split(':');
//console.log('newDLList' + newDLList);
if (newDLList.includes(menuStrainName)) {
 // console.log('dup');
  wnotify('Error: Duplicate dislike');
  setMenuStrainName("");
  setMenuStrainId("");
  handleToClose3();
} else {
  if (newLList.includes(menuStrainName)) {
    //Already in Likes
    wnotify('Error: Strain already on likes list');
    setMenuStrainName("");
    setMenuStrainId("");
    handleToClose3();
  } else {

 //Update and Save User Doc
    if (oldDLList === "none") {
      updateDislikes( menuStrainName );
      setDislikeList(menuStrainName);
    } else {
      updateDislikes( oldDLList + ":" + menuStrainName  );
      setDislikeList( oldDLList + ":" + menuStrainName )
    };
    snotify('Strain added to dislikes list');
    setMenuStrainName("");
    setMenuStrainId("");
    handleToClose3();
  };
};
};



async function addStrain(){
  //Input Validation

  //Verify Name
  if (comment1 !== '') {         
//Check for duplicates
//console.log( " dup" + nameList.includes(comment1) )
if ( nameList.includes(comment1) ) {
  //duplicate found
  wnotify('Error: Duplicate Name found');   
} else {

/////Verify type is one of 3 types
//console.log(comment2.toUpperCase());
if (comment2.toUpperCase() === 'INDICA' || comment2.toUpperCase() === 'SATIVA' || comment2.toUpperCase() === 'HYBRID' ) {   
  //Verify TCH/CBD are 1-100 percent numbers

//console.log("nan" + isNaN(comment3));
if ( isNaN(comment3) || isNaN(comment4) || comment3 === '' || comment4 === '' || comment3 > 100 || comment4 > 100 || comment3 < 0 || comment4 < 0 )  {  
    //THC/CBD is not a number, blank or out f range
      wnotify('Error: TCH/CBD must be a number from 1 to 100');  
  } else {

      //Verify Effect
    if (comment5 ==='') {
      //Effect is blank
      wnotify('Error: Effect can not be blank'); 
    } else {


      //Verify Flavor
      if (comment6 ==='') {
        //Flavor is blank
        wnotify('Error: Flavor can not be blank'); 
      } else {


            //add new strain  
            setLoading('1');
            try {
           // console.log('creating new strain record')
              if (comment2 === "indica") {var tmpColor = appConfig["color-indica"]} else if (comment2 === "sativa" ) { tmpColor = appConfig["color-sativa"]  } else { tmpColor = appConfig["color-hybrid"]};

            const result = await client.graphql({
              query: createTodo,
             variables: {
               input: {
                 name: newType ,
                description: comment1 ,
                 type: comment2 ,
                effect: comment5 ,
                flavor: comment6,
                thc: comment3 +'%' ,
                cbd: comment4 + '%' ,
                color: tmpColor,
                location: 'Test Store', 
               }
             }
            });
           // console.log('flower strain created');
           // alert('Strain Added!');
           snotify('New Strain Added'); 
           //Reset Form
            setComment1('');
            setComment2('');
            setComment3('');
            setComment4('');
            setComment5('');
             setComment6('');
         } catch (e) {
            console.log('Create new strain Failed: ', e);
           alert('Error Creating New Strain!');
          };









            };
          };
        };
      } else {
        //Type is incorrect
        wnotify('Error: Type Value must be Indica, Sativa or Hybrid only'); 

      };
    };
  } else {
      //Error Name was Blank
     wnotify('Error: Name can not be blank'); 
  };
};



	const getStrainsAndUserLD = async (props) => {

//console.log('loading strains')
    try {
    
      const todos = await client.graphql({
        query: todosByDescription,
        variables: { name: props,  limit: 2000 }
      });


     //console.log(todos);
     const todoList = todos.data.todosByDescription.items;
      setStrainList(todoList);
      setScount(todoList.length);
     // console.log(todoList);

   setNameList( todoList.map((strain) => ( strain.description )));
   //console.log(nameList);

      setLoading('0');
    } catch (e) {
      console.log('getStrains failed: ', e);
  
    };
  
    
    //Get Likes/Dislikes
    const { username, userId} = await getCurrentUser();
    //Set products user
    setUname(username);

    try {
      //Get user doc
     const oneTodo = await client.graphql({
             query: todosByDescription,
             variables: { name: username }
               });
               console.log("Products User:" + username);    
         //  console.log(oneTodo);
                 //Get Current L/D
                    console.log("Getting L/D");
                    var tmpListL= oneTodo.data.todosByDescription.items[0].likes;
                    var tmpListD= oneTodo.data.todosByDescription.items[0].dislikes;
            //        console.log("L:" + tmpListL);
            //        console.log("D:" + tmpListD);
                    var tmpList2L = [tmpListL];
                    var tmpList2D = [tmpListD];
           //         console.log(tmpList2L);
           //         console.log(tmpList2D);
                    setLikeList(tmpList2L);
                    setDislikeList(tmpList2D);


         } catch (error) {
             console.log('error produts get user' , error);








             try {
              //Create new user
              console.log('creating new user record')
                const result = await client.graphql({
              query: createTodo,
              variables: {
                input: {
                 name: username ,
                 description: 'user doc' ,
                 settings: appConfig.settings,
                 likes: appConfig.likes,
                 dislikes: appConfig.dislikes,
                 history: appConfig.history
                }
              }
            });



            console.log('user created');
          } catch (e) {
            console.log('Create new user Failed(Products): ', e);
          };







           };
	}; 


async function updateLikes(props) {


    //apply New LikeList  
             try {
           
             //Get user doc
             const oneTodo = await client.graphql({
             query: todosByDescription,
             variables: { name: uname }
             });
    //         console.log("updateLikesDoc User:" + uname);    
      //       console.log(oneTodo);
 

             if (currentType === 'cartridge' ) {
                //CARTRIDGE
                  var todoDetails = {
                  id: oneTodo.data.todosByDescription.items[0].id,
                  cartLikes: props
                    };      
             } else if (currentType === 'concentrate' ) {
                      //CONCENTRATE
                      todoDetails = {
                        id: oneTodo.data.todosByDescription.items[0].id,
                        concLikes: props
                    };      
             } else if (currentType === 'eddable' ) {
                      //EDDABLE
                      todoDetails = {
                        id: oneTodo.data.todosByDescription.items[0].id,
                        eddLikes: props
                    };      
             } else {
              //FLOWER
                todoDetails = {
                  id: oneTodo.data.todosByDescription.items[0].id,
                  likes: props
              };      
             };



         

             const updatedTodo = await client.graphql({
               query: updateTodo,
               variables: { input: todoDetails }
               
             });
       //     console.log('likes saved');       
        //   console.log(updatedTodo);
            
          } catch (e) {
             console.log('save like error: ', e);
         };
   };

   async function updateDislikes(props) {
   
    //apply New DisLikeList  
    try {
           
      //Get user doc
      const oneTodo = await client.graphql({
      query: todosByDescription,
      variables: { name: uname }
      });
//      console.log("updateDisLikesDoc User:" + uname);    
//       console.log(oneTodo);

   


      if (currentType === 'cartridge' ) {
        //CARTRIDGE
          var todoDetails = {
          id: oneTodo.data.todosByDescription.items[0].id,
          cartDislikes: props
            };      
     } else if (currentType === 'concentrate' ) {
              //CONCENTRATE
              todoDetails = {
                id: oneTodo.data.todosByDescription.items[0].id,
                concDislikes: props
            };      
     } else if (currentType === 'eddable' ) {
              //EDDABLE
              todoDetails = {
                id: oneTodo.data.todosByDescription.items[0].id,
                eddDislikes: props
            };      
     } else {
      //FLOWER
       todoDetails = {
          id: oneTodo.data.todosByDescription.items[0].id,
          dislikes: props
    };      

     };






      const updatedTodo = await client.graphql({
        query: updateTodo,
        variables: { input: todoDetails }
      });
 //    console.log('dislikes saved');       
 //   console.log(updatedTodo);
     
   } catch (e) {
      console.log('save dislike error: ', e);
  };
   };


function goBack() {
  navigate(`/`);
};

function goFavorites() {
  navigate(`/favorites`);
};




if (loading === '1') { var tmpHideA = true; var tmpHideB = false } else { tmpHideA = false; tmpHideB = true};

  return (
    <>


<div className="Headder"       
        >
          <span style={{ }}>
        {ver}
        </span>

 

        <span style={{paddingLeft:'1vw'}} >

        <span style={{ paddingRight:"4px",fontSize:"10px"}} >
 
        </span>
        
        
        

        <button className="HeadderButton" onClick={handleClickToOpen1}>New Strain</button>
        
        <button className="HeadderButton"onClick={goBack}>Back</button>
        
        </span>


        </div>
   
    



<div className = "main2">

<div className = "title">
<select name="mode" onChange={(e) => handleChangeCurrentType(e.target.value)} >
  <option value="flower">Flower</option>
  <option value="cartridge">Cartridge</option>
  <option value="concentrate">Concentrate</option>
  <option value="eddible">Eddible</option>
</select>
<span style={{paddingLeft:'6px'}}>
Strains ({scount})
</span>

</div>


<div hidden = {tmpHideA} className = "body-products">

<div className='thread__container'>
                    {strainList.map((thread,idx) => (
                        <div  style={{	boxShadow: '0 0 0px 0px yellow', display: "flex",  backgroundColor:'#4c663b', flexDirection:'column' , minWidth:'90vw', maxWidth:'90vw',  margin:'5px', borderRadius:"3px"}} key={`song${idx}`}>

                            <div style={{ boxShadow: '0 0 0px 0px yellow',  display:"flex",backgroundColor:'', rowGap:'0vw', columnGap:'1vw', flexDirection:'row' , justifyItems:'flex-start' , justifyContent:'space-between', paddingTop:'1px', minWidth:'90vw', maxWidth:'90vw', minHeight:'45px', alignItems:"center", textAlign:'center'}}>
                            
                            <div style={{boxShadow: '0 0 0px 0px green',  display:"flex", flexDirection:'column' ,backgroundColor:'', minWidth:'22vw',maxWidth:'22vw', textAlign:'left', paddingLeft:'1.7vw', color:'white', fontSize:'calc(10px + .5vw)', flexWrap:'wrap', rowGap:'15px', columnGap:'15px'}}>{thread.description}</div>
                            
                            <div style={{boxShadow: '0 0 0px 0px green',  display:"flex", flexDirection:'row' ,backgroundColor: thread.color  , minWidth:'8.5vw',maxWidth:'10vw', paddingLeft:'6px',paddingRight:'6px', paddingTop:'10px', paddingBottom:'10px', textAlign:'left', color:'white',  fontSize:'calc(8px + .5vw)'}}> {thread.type.charAt(0).toUpperCase() + thread.type.slice(1)}</div>
                            <div style={{boxShadow: '0 0 0px 0px green',  display:"flex", flexDirection:'row' ,backgroundColor:"", minWidth:'17vw',maxWidth:'17vw', textAlign:'left', color:'white',  fontSize:'calc(8px + .5vw)'}}> THC:{thread.thc} CBD:{thread.cbd}  </div>

                            <div style={{boxShadow: '0 0 0px 0px green',  display:"flex", rowGap:'2vw', columnGap:'2vw', flexDirection:'row' , justifyItems:'center' , justifyContent:'center' ,  alignItems:'center', textAlign:'center'}}>
                          
                            <div style={{boxShadow: '0 0 0px 0px green',  display:"flex", flexDirection:'row' , backgroundColor:'', minWidth:'18vw',maxWidth:'20vw', textAlign:'left', color:'white',  fontSize:'calc(8px + .5vw)'}}> Effect:{thread.effect} Flavor:{thread.flavor}  </div>
                                
                          <Comments
                              numberOfComments={0}
                              threadId={thread.id}
                              title={thread.description}
                          />
                      
                            <div style={{boxShadow: '0 0 0px 0px green',  display:"flex", flexDirection:'column' , backgroundColor:'#4e4e4e', minWidth:'5vw',maxWidth:'5vw', minHeight:'35px' , maxHeight:'35px' , textAlign:'left', color:'white',  fontSize:'calc(8px + .5vw)'}}>   
                      
                      
                            <MenuButtonStrain 
                                   bugId={thread.id}
                                   bugDesc={thread.description}
                                   bugType={thread.type}
                                   funct={handleClickToOpen3} 
                                   likes={likeList}
                                   dislikes={dislikeList}
                            />

                            </div>
                
                        
                          
                                </div>
                        

                                <span style={{paddingRight:'4px'}}>
                  
                          </span>  

                            </div>
                           

                        </div>
                    ))}
                </div>

                </div>

<div hidden = {tmpHideB} className = "loadingPage" style={{minHeight:'101vh'}} >
  
  <img src={logo} className="App-logo" alt="logo" />

</div>




    

  </div>





  <div stlye={{}}> 
      <Dialog open={open1} onClose={handleToClose1}>
        <DialogTitle style={{backgroundColor:'#242424', color:'#d8d8d8', borderBottomStyle:'none', borderTopStyle:'solid',borderLeftStyle:'solid',borderRightStyle:'solid', borderWidth:'2px', borderColor:'#4c663b'}}>
          New <select name="mode" onChange={(e) => handleChangeNewType(e.target.value)} >
  <option value="flower">Flower</option>
  <option value="cartridge">Cartridge</option>
  <option value="concentrate">Concentrate</option>
  <option value="eddible">Eddible</option>
</select> Strain
        </DialogTitle>
        <DialogContent style={{backgroundColor:'#242424', color:'#d8d8d8', borderBottomStyle:'none', borderTopStyle:'none',borderLeftStyle:'solid',borderRightStyle:'solid', borderWidth:'2px', borderColor:'#4c663b'}}>
          <DialogContentText style={{backgroundColor:'#242424', color:'#d8d8d8'}}>        
      
          

    <label style={{paddingRight:'1px'}}>Strain Name</label>
    <input value={comment1} onChange={(e) => setComment1(e.target.value)} type='text' name='comment1' className='inp-field'/><br></br>

    <label style={{paddingRight:'8px'}}>Strain Type</label>
    <select id = "comment2" 
            name = 'comment2'
            onChange={(e) => setComment2(e.target.value)}
             className='inp-field'
             
    >
    <option value="hybrid">Hybrid</option>
    <option value="indica">Indica</option>
    <option value="sativa">Sativa</option>
    
    </select>
    <br></br><br></br>

 <label style={{paddingRight:'43px'}}>THC%</label>
    <input
        value={comment3}
        onChange={(e) => setComment3(e.target.value)}
        type='text'
        name='comment3'
        id='comment3'
        className='inp-field'
    />
<br></br>
<label style={{paddingRight:'42px'}}>CBD%</label>
    <input
        value={comment4}
        onChange={(e) => setComment4(e.target.value)}
        type='text'
        name='comment4'
        id='comment4'
        className='inp-field'
    />
    <br></br>
    <label style={{paddingRight:'8px'}}>Main Effect</label>
 
    <select id = "comment5" 
            name = 'comment5'
            onChange={(e) => setComment5(e.target.value)}
             className='inp-field'
    >

    <option value="Calming">Calming</option>
    <option value="Ceative">Ceative</option>
    <option value="Energetic">Energetic</option>
    <option value="Euphoric">Euphoric</option>
    <option value="Focused">Focused</option>
    <option value="Giddy">Giddy</option>
    <option value="Happy">Happy</option>
    <option value="Hungry">Hungry</option>
    <option value="Relaxed">Relaxed</option>
    <option value="Sleepy">Sleepy</option>
    </select>
    <br></br><br></br>
    <label style={{paddingRight:'5px'}} >Main Flavor</label>
    <select id = "comment6" 
            name = 'comment6'
            onChange={(e) => setComment6(e.target.value)}
             className='inp-field'
    >

    <option value="Apple">Apple</option>
    <option value="Apricot">Apricot</option>
    <option value="Blueberry">Blueberry</option>
    <option value="Cheese">Cheese</option>
    <option value="Citris">Citris</option>
    <option value="Diesel">Diesel</option>
    <option value="Earthy">Earthy</option>
    <option value="Floral">Floral</option>
    <option value="Grape">Grape</option>
    <option value="Honey">Honey</option>
    <option value="Lemon">Lemon</option>
    <option value="Mint">Mint</option>
    <option value="Nutty">Nutty</option>
    <option value="Pepper">Pepper</option>
    <option value="Pine">Pine</option>
    <option value="Pineapple">Pineapple</option>
    <option value="Skunk">Skunk</option>
    <option value="Spicy">Spicy</option>
    <option value="Strawberry">Strawberry</option>
    <option value="Sweet">Sweet</option>
    <option value="Woody">Woody</option>
    
    </select>

          </DialogContentText> 
        </DialogContent>
        <DialogActions style={{backgroundColor:'#242424', color:'#d8d8d8', borderBottomStyle:'solid', borderTopStyle:'none',borderLeftStyle:'solid',borderRightStyle:'solid', borderWidth:'2px', borderColor:'#4c663b'}}> 
        <Button style={{backgroundColor:'', color:'#80b35e'}} onClick={handleSubmit1}>
            Save
          </Button> 
          <Button style={{backgroundColor:'', color:'#a0a0a0'}} onClick={handleToClose1}>
            Cancel
          </Button> 
        </DialogActions>      
      </Dialog>
    </div>



    <div stlye={{}}> 
      <Dialog open={open1e} onClose={handleToClose1e}>
        <DialogTitle style={{backgroundColor:'#242424', color:'#d8d8d8', borderBottomStyle:'none', borderTopStyle:'solid',borderLeftStyle:'solid',borderRightStyle:'solid', borderWidth:'2px', borderColor:'#4c663b'}}>
          Edit {currentType} Strain
        </DialogTitle>
        <DialogContent style={{backgroundColor:'#242424', color:'#d8d8d8', borderBottomStyle:'none', borderTopStyle:'none',borderLeftStyle:'solid',borderRightStyle:'solid', borderWidth:'2px', borderColor:'#4c663b'}}>
          <DialogContentText style={{backgroundColor:'#242424', color:'#d8d8d8'}}>        
      
          

    <label style={{paddingRight:'1px'}}>Strain Name:</label>
    {menuStrainName}<br></br>

    <label style={{paddingRight:'8px'}}>Strain Type</label>
    <select id = "comment2" 
            name = 'comment2'
            onChange={(e) => setComment2(e.target.value)}
             className='inp-field'
         
    >
    <option selected={sel1} value="hybrid">Hybrid</option>
    <option selected={sel2} value="indica">Indica</option>
    <option selected={sel3} value="sativa">Sativa</option>
    
    </select>
    <br></br><br></br>

 <label style={{paddingRight:'43px'}}>THC%</label>
    <input
        value={comment3}
        onChange={(e) => setComment3(e.target.value)}
        type='text'
        name='comment3'
        id='comment3'
        className='inp-field'
    />
<br></br>
<label style={{paddingRight:'42px'}}>CBD%</label>
    <input
        value={comment4}
        onChange={(e) => setComment4(e.target.value)}
        type='text'
        name='comment4'
        id='comment4'
        className='inp-field'
    />
    <br></br>
    <label style={{paddingRight:'8px'}}>Main Effect</label>
 
    <select id = "comment5" 
            name = 'comment5'
            onChange={(e) => setComment5(e.target.value)}
             className='inp-field'
    >

    <option value="Calming">Calming</option>
    <option value="Ceative">Ceative</option>
    <option value="Energetic">Energetic</option>
    <option value="Euphoric">Euphoric</option>
    <option value="Focused">Focused</option>
    <option value="Giddy">Giddy</option>
    <option value="Happy">Happy</option>
    <option value="Hungry">Hungry</option>
    <option value="Relaxed">Relaxed</option>
    <option value="Sleepy">Sleepy</option>
    </select>
    <br></br><br></br>
    <label style={{paddingRight:'5px'}} >Main Flavor</label>
    <select id = "comment6" 
            name = 'comment6'
            onChange={(e) => setComment6(e.target.value)}
             className='inp-field'
    >
<option value="Apple">Apple</option>
    <option value="Apricot">Apricot</option>
    <option value="Blueberry">Blueberry</option>
    <option value="Cheese">Cheese</option>
    <option value="Citris">Citris</option>
    <option value="Diesel">Diesel</option>
    <option value="Earthy">Earthy</option>
    <option value="Floral">Floral</option>
    <option value="Grape">Grape</option>
    <option value="Honey">Honey</option>
    <option value="Lemon">Lemon</option>
    <option value="Mint">Mint</option>
    <option value="Nutty">Nutty</option>
    <option value="Pepper">Pepper</option>
    <option value="Pine">Pine</option>
    <option value="Pineapple">Pineapple</option>
    <option value="Skunk">Skunk</option>
    <option value="Spicy">Spicy</option>
    <option value="Strawberry">Strawberry</option>
    <option value="Sweet">Sweet</option>
    <option value="Woody">Woody</option>
    </select>

          </DialogContentText> 
        </DialogContent>
        <DialogActions style={{backgroundColor:'#242424', color:'#d8d8d8', borderBottomStyle:'solid', borderTopStyle:'none',borderLeftStyle:'solid',borderRightStyle:'solid', borderWidth:'2px', borderColor:'#4c663b'}}> 
        <Button style={{backgroundColor:'', color:'#80b35e'}} onClick={handleSubmit1e}>
            Save
          </Button> 
          
          <Button style={{backgroundColor:'', color:'#a0a0a0'}} onClick={handleToClose1e}>
            Cancel
          </Button> 
        </DialogActions>      
      </Dialog>
    </div>



    <div stlye={{}}> 
      <Dialog open={open2} onClose={handleToClose2}>
        <DialogTitle>
          Title
        </DialogTitle>
        <DialogContent >
          <DialogContentText>
          ...Under Construction...      
          </DialogContentText> 
        </DialogContent>
        <DialogActions> 
          <ButtonMui onClick={handleToClose2}>
            Exit
          </ButtonMui> 
        </DialogActions>      
      </Dialog>
    </div>




    <div style={{}}> 
      <Dialog open={open3} onClose={handleToClose3} >
        <DialogTitle style={{backgroundColor:'#242424', color:'#d8d8d8', borderBottomStyle:'none', borderTopStyle:'solid',borderLeftStyle:'solid',borderRightStyle:'solid', borderWidth:'2px', borderColor:'#4c663b'}}>
          {menuStrainName}
        </DialogTitle>
        <DialogContent style={{backgroundColor:'#242424', color:'white', borderStyle:'none', borderBottomStyle:'solid', borderTopStyle:'none',borderLeftStyle:'solid',borderRightStyle:'solid', borderWidth:'2px', borderColor:'#4c663b'}}>
          <DialogContentText>
            
            <Flex
              direction='column'
            >

                      <Button style={{minWidth:'100px', backgroundColor:'', color:"#a0a0a0"}} onClick={handleLikeListChange} >Like</Button>
                      <Button style={{minWidth:'100px', backgroundColor:'', color:"#a0a0a0"}} onClick={handleDislikeListChange} >Dislike</Button>
                      <Button style={{minWidth:'100px', backgroundColor:'', color:"#a0a0a0"}} onClick={handleClickToOpen1e} >Edit</Button>
                      <Button style={{minWidth:'100px', backgroundColor:'', color:"#a0a0a0"}} onClick={handlePeff} >Effect</Button>
                      <Button style={{minWidth:'100px', backgroundColor:'', color:"#a0a0a0"}} onClick={handlePnote} >Note</Button>
                      <Button style={{display:'none', visibility:'hidden', minWidth:'100px', backgroundColor:'', color:"#a0a0a0"}} onClick={handleRemoveStrain} >Delete</Button>
                      <Button style={{minWidth:'100px', backgroundColor:'', color:"#a0a0a0"}} onClick={handleToClose3}>Close</Button>
                   
      
            </Flex>

              


          </DialogContentText> 
        </DialogContent>

      </Dialog>
    </div>



    <div stlye={{}}> 
    <Dialog open={open4} onClose={handleToClose4}>
        <DialogTitle style={{backgroundColor:'#242424', color:'#d8d8d8', borderBottomStyle:'none', borderTopStyle:'solid',borderLeftStyle:'solid',borderRightStyle:'solid', borderWidth:'2px', borderColor:'#4c663b'}}>
          Edit Personal Effect
        </DialogTitle>
        <DialogContent style={{backgroundColor:'#242424', color:'#d8d8d8', borderBottomStyle:'none', borderTopStyle:'none',borderLeftStyle:'solid',borderRightStyle:'solid', borderWidth:'2px', borderColor:'#4c663b'}} >
          <DialogContentText style={{backgroundColor:'#242424', color:'#d8d8d8'}}>
    

    <input
        value={comment6}
        onChange={(e) => setComment4(e.target.value)}
        type='text'
        name='comment4'
        id='comment4'
        className='inp-field'
        style={{width:'200px'}}
    />

<Flex 
          direction="column"
          gap = "10px"
          paddingTop='15px'
          >
              <Button  style={{backgroundColor:'', color:"#80b35e" , fontWeight:'normal'}} onClick={handleSubmit4}  >Save</Button>
              <Button  style={{backgroundColor:'', color:"#a0a0a0" , fontWeight:'normal'}} onClick={handleToClose4}  >Cancel</Button>
              </Flex>

          </DialogContentText> 
        </DialogContent>
       
      </Dialog>
    </div>



    <div stlye={{}}> 
    <Dialog open={open5} onClose={handleToClose5}>
        <DialogTitle style={{backgroundColor:'#242424', color:'#d8d8d8', borderBottomStyle:'none', borderTopStyle:'solid',borderLeftStyle:'solid',borderRightStyle:'solid', borderWidth:'2px', borderColor:'#4c663b'}}>
          
             Edit Personal Note

        </DialogTitle>
        <DialogContent style={{backgroundColor:'#242424', color:'#d8d8d8', borderBottomStyle:'solid', borderTopStyle:'none',borderLeftStyle:'solid',borderRightStyle:'solid', borderWidth:'2px', borderColor:'#4c663b'}}>
          <DialogContentText>
            
          <Flex 
          direction="column"
          textAlign="center"
          alignItems="center"
 
          minWidth="200px"
            
          minHeight="55vh"
          gap="0.5vw"
          paddingTop="1vw"
          fontSize="14px"
          >
            
			<form  onSubmit={handleSubmit5}>
    
        <textarea
            
            rows={17}
			cols={40}
           
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            type='text'
            name='comment'
            
        />

             <Flex 
          direction="column"
          gap = "10px"
          paddingTop='15px'
          >
              <Button  style={{backgroundColor:'', color:"#80b35e" , fontWeight:'normal'}} onClick={handleSubmit5}  >Save</Button>
              <Button  style={{backgroundColor:'', color:"#a0a0a0" , fontWeight:'normal'}} onClick={handleToClose5}  >Cancel</Button>
              </Flex>
		</form>

          
            
            </Flex>


          </DialogContentText> 
        </DialogContent>
        
      </Dialog>
      </div>
    



    </>
);

};

export default withAuthenticator(Products);
