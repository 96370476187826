import React, { useState} from "react";
import { generateClient } from 'aws-amplify/api';
import { toast } from "react-toastify";
import { createTodo, updateTodo, deleteTodo } from '../graphql/mutations';
import { listTodos, getTodo, todosByDescription } from '../graphql/queries';
import { Amplify } from 'aws-amplify';
import awsExports from '../aws-exports';
import { getCurrentUser } from 'aws-amplify/auth';
Amplify.configure(awsExports);
const client = generateClient();

function EditButtonStrain( props)  {

  //function snotify(note) {toast.success(note);};
  //function inotify(note) {toast.info(note)};
  function enotify(note) {toast.error(note)};
  //function wnotify(note) {toast.warning(note)};

    const client = generateClient();


    const [state, setState] = useState("--");




    function stateChange(f) {
        setState(f);
        props.funct(f);
    //   to set loader
  };




    async function handleEdit() {
        
       const { username, userId} = await getCurrentUser();
      enotify('edit disabled')
          
    };






    return (
            <>
            <button hidden className='product' style={{borderWidth:'0px',backgroundColor:'darkgrey', color:'white', paddingLeft:'8px'}} onClick={handleEdit} >||</button> 
            </>
    );
};

export default EditButtonStrain;